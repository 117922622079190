import { resolveRequest } from "./api";
import facilityManagementApi from "./facilityManagementApi";
import rolesAndPrivilegesApi from "./rolesAndPrivilegesApi";
import escalationRulesApi from "./escalationRulesApi";
import customFieldApi from "./customFieldApi";
import notificationEscalationApi from "./notificationEscalationApi"
import factoryFeedbackApi from "./factoryFeedback"
import workHoursApi from "./workHoursApi"
import contactAdminApi from "./contactAdminApi"
import appConfigApi from "./appConfigApi"
import assetMetadataApi from "./assetMetadataApi";
import hseMetricsApi from "./hseMetricsApi";

export default {
  resolveRequest,
  facilityManagementApi,
  rolesAndPrivilegesApi,
  escalationRulesApi,
  customFieldApi,
  notificationEscalationApi,
  factoryFeedbackApi,
  workHoursApi,
  contactAdminApi,
  appConfigApi,
  assetMetadataApi,
  hseMetricsApi
};
export {
  resolveRequest,
  facilityManagementApi,
  rolesAndPrivilegesApi,
  escalationRulesApi,
  customFieldApi,
  notificationEscalationApi,
  factoryFeedbackApi,
  workHoursApi,
  contactAdminApi,
  appConfigApi,
  assetMetadataApi,
  hseMetricsApi
};
