import React, { createContext, useEffect, useState } from 'react';
import { resolveRequest, appConfigApi } from "@apis";
import { safeJsonParse } from '@iso/utils/helpers';

export const CustomLocalTranslationContext = createContext({});

const sidebarLabels = {
  "Home": true,
  "Actions": true,
  "Tasks": true,
  "Issues & Observations": true,
  "Incidents": true,
  "Inspections": true,
  "EHS Library": true,
  "Administration": true
};

window.fetchConfigRetriesCount = 1;

export const CustomLocalTranslationProvider = ({ children }) => {
  const [appConfig, setAppConfig] = useState({});
  const [translations, setTranslations] = useState({});
  const [isFetchingCustomLocalTranslations, setIsFetchingCustomLocalTranslations] = useState(false);
  const [appConfigLongestTranslationTextLength, setAppConfigLongestTranslationTextLength] = useState(false);

  const fetchTranslations = () => new Promise(async (resolve) => {
    setIsFetchingCustomLocalTranslations(true);

    const promise = appConfigApi.fetchAppConfig();
    const { data = [], error } = await resolveRequest(promise);

    setIsFetchingCustomLocalTranslations(false);

    if (error) return resolve({ error });

    let translationTextLength = 0;
    const appConfigMap = {};
    const translationsMap = data?.reduce((m, d) => {
      const name = d.aliasName || d.name;
      m[d.name] = name;

      appConfigMap[d.name] = d;

      if (name === "dashboard_menu") m[d.name] = d.value;
      if (name === "freq_rate_subscription") m[d.name] = d.value;
      if (name === "ra_subscription") m[d.name] = d.value;
      if (name === "app_theme") {
        m[d.name] = d.value;
        const appConfig = safeJsonParse(d.value);
        m["app-name"] = appConfig?.["app-name"] || "SafetyQube";
      }
      if (name === "hsem_du_cutoff") m[d.name] = d.value;

      if (sidebarLabels[d.name] && translationTextLength < name.length) translationTextLength = name.length;

      return m;
    }, {});

    translationsMap["Location"] = translationsMap["Area"];

    setAppConfigLongestTranslationTextLength(translationTextLength);

    setTranslations(translationsMap);
    setAppConfig(appConfigMap);

    resolve({ error: false });
  })

  const retryFetchConfigCall = async (resetCount) => {
    if (resetCount) window.fetchConfigRetriesCount = 1;

    const { error } = await fetchTranslations();
    
    if (error && window.fetchConfigRetriesCount <= 3) {
      window.fetchConfigRetriesCount += 1;
      retryFetchConfigCall();
    }
  }

  useEffect(() => {
    const token = localStorage.getItem('token');

    if (token) {
      retryFetchConfigCall();
    }
  }, [])

  return (
    <CustomLocalTranslationContext.Provider value={{
      translations,
      setTranslations,
      isFetchingCustomLocalTranslations,
      fetchCustomLocalTranslations: fetchTranslations,
      appConfigLongestTranslationTextLength,
      appConfig
    }}>
      {children}
    </CustomLocalTranslationContext.Provider>
  );
}