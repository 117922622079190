import requestUM from "./apiUM";
// const prefix = "http://100.24.253.101:8081";
const userManagementApi = {
  // getEmployees: () => requestUM.get(prefix + "/aum/um/"),
  getEmployees: () => requestUM.get("/aum/um/"),
  getEmployeesBrief: () => requestUM.get("/aum/um/brief/"),
  getFacilities: () => requestUM.get("aum/um/facilities/"),
  createEmployee: (data) => requestUM.post("/aum/um/employee", data),
  updateEmployee: (data) => requestUM.patch("/aum/um/employee", data),
  bulkUploadEmployee: (data) => requestUM.post("/aum/um/employee/bulk", data),
  fetchFacilityEmployee: () => requestUM.get("/aum/um/facilities"),
  postEmployeeDP: (employeeId, data) =>
    requestUM.post(`/aum/um/employee/dp/${employeeId}`, data),
  manageNotification: (id, data) =>
    requestUM.patch(`/aum/um/managenotification/${id}`, data),
  employeeStatus: (query) =>
    requestUM.put(`/aum/um/status?${query}`),
  deleteEmployee: (employeeId, delegatedEmployeeId) => requestUM.delete(`/aum/um?employeeId=${employeeId}&delegatedEmployeeId=${delegatedEmployeeId}`),
  getEmployeeID: (employeeId) => requestUM.get(`/aum/um/${employeeId}`),
  getNotificationID: (employeeId) =>
    requestUM.get(`/aum/um/managenotification/${employeeId}`),
  getEmployeeNotification: () => requestUM.get("/aum/notification/"),
  putEmployeeNotificationRead: (notificationId) =>
    requestUM.put(`/aum/notification/${notificationId}`),
  putNotificationRead: () =>
    requestUM.put(`/aum/notification`),
  getTrainingTileURL: () => requestUM.get('/aum/um/training/link'),
  delegateUser: (data = {}, queryParams) => requestUM.put(`/aum/um/delegate?${queryParams}`, data) 
};

export default userManagementApi;
