import React, { lazy } from "react";

export const PUBLIC_ROUTE = {
  LANDING: "/",
  SIGN_IN: "/signin",
  SIGN_UP: "/signup",
  FORGET_PASSWORD: "/forgotpassword",
  RESET_PASSWORD: "/resetpassword",
  PAGE_404: "/404",
  PAGE_500: "/500",
  AUTH0_CALLBACK: "/auth0loginCallback",
  AUTH: "/auth",
  REDIRECT: "/redirect",
  QR_CODE_LOGIN: "/qr-code",
  QR_CODE_LOGIN_SUCCESS: "/qr-code-success",
  PAGE_403: "/403",
  PAGE_503: "/503",
};

export const PRIVATE_ROUTE = {
  DASHBOARD: "/",
  ARCHIVE: "/closed",
  CUSTOMFIELDS: "/custom-fields",
  RULES: "/rules",
  ROLES: "/roles",
  NOTIFICATIONGROUPS: "/user-groups",
  USERMANAGEMENT: "/user-management",
  LEADERBOARD: "/leaderboard",
  CKECKLIST: "/checklist",
  ALLISSUES: "/in-progress",
  RAISEINCIDENT: "/raise-incident",
  RAISEHAZARD: "/hazard/raise",
  RAISE: "/raise",
  REPORTS: "/reports",
  DEEPDIVE: "/deep-dive",
  MYACTIONS: "/my-actions",
  HOME: "/home",
  NOTIFICATION: "/notification",
  DROPZONE: "/dropzone",
  PROFILE: "/my-profile",
};
const ExecuteIncidentComponent = lazy(() => import('@iso/containers/Incidents/ExecuteIncident'));


const AuthComponent = lazy(() =>
  import("@iso/containers/Auth")
);

export const publicRoutes = [
  {
    path: PUBLIC_ROUTE.QR_CODE_LOGIN_SUCCESS,
    component: lazy(() => import('@iso/components/QRCode/SuccessScreen')),
  },
  {
    path: PUBLIC_ROUTE.QR_CODE_LOGIN,
    component: lazy(() => import('@iso/components/QRCode/QrCodeLogin')),
  },
  {
    path: PUBLIC_ROUTE.LANDING,
    exact: true,
    component: lazy(() => import("@iso/containers/Pages/SignIn/SignIn")),
  },
  {
    path: PUBLIC_ROUTE.PAGE_404,
    component: lazy(() => import("@iso/containers/Pages/404/404")),
  },
  {
    path: PUBLIC_ROUTE.PAGE_500,
    component: lazy(() => import("@iso/containers/Pages/500/500")),
  },
  {
    path: PUBLIC_ROUTE.SIGN_IN,
    component: lazy(() => import("@iso/containers/Pages/SignIn/SignIn")),
  },
  {
    path: PUBLIC_ROUTE.SIGN_UP,
    component: lazy(() => import("@iso/containers/Pages/SignUp/SignUp")),
  },
  {
    path: PUBLIC_ROUTE.FORGET_PASSWORD,
    component: lazy(() =>
      import("@iso/containers/Pages/ForgotPassword/ForgotPassword")
    ),
  },
  {
    path: PUBLIC_ROUTE.RESET_PASSWORD,
    component: lazy(() =>
      import("@iso/containers/Pages/ResetPassword/ResetPassword")
    ),
  },
  {
    path: PUBLIC_ROUTE.AUTH,
    component: AuthComponent,
  },
  {
    path: PUBLIC_ROUTE.REDIRECT,
    component: () => <AuthComponent authType="redirect" />,
  },
  {
    path: PUBLIC_ROUTE.PAGE_403,
    component: lazy(() => import("@iso/components/AccessDenied/AccessDenied")),
  },
  {
    path: PUBLIC_ROUTE.PAGE_503,
    component: lazy(() => import("@iso/components/UnderMaintenance/UnderMaintenance")),
  }
  // {
  //   path: PUBLIC_ROUTE.AUTH0_CALLBACK,
  //   component: lazy(() =>
  //     import("@iso/containers/Authentication/Auth0/Auth0Callback")
  //   ),
  // },
];


export const serviceAccountRoutes =  [
  {
    path: 'task/:id',
    component: lazy(() => import('@iso/containers/Raise/RaiseForm/RaiseTask'))
  },
  {
    path: 'incidents/raise-incident/execute/template/:scheduleId',
    component: ExecuteIncidentComponent
  },
  {
    path: 'issues/raise-issue/execute/template/:scheduleId',
    component: () => <ExecuteIncidentComponent isIssuesPage={true} issueType="issue" />
  },
  {
    path: 'issues/raise-issue/execute/:executionId',
    component: () => <ExecuteIncidentComponent isIssuesPage={true} issueType="issue" />
  },
  {
    path: 'incidents/raise-incident/execute/:executionId',
    component: ExecuteIncidentComponent
  },
  {
    path: 'inspections/execute/:scheduleId',
    component: lazy(() =>
      import('@iso/containers/NewInspection/ExecuteInspection')
    ),
  },
  {
    path: 'qr-code-success',
    component: lazy(() => import('@iso/components/QRCode/SuccessScreen'))
  },
];

export const isPublicRoute = (route) => {
  return publicRoutes.some(publicRoute => regexCompareTheRoutes(publicRoute, route));
}

const regexCompareTheRoutes = (route, path) => {
  const compatibleRouteRegex = route.replace(/:scheduleId|:id|:executionId/, '[0-9]+').replace(/\//g, '\\/');
  const regex = new RegExp(compatibleRouteRegex + '$');
  return regex.test(path);
}

export const isCurrentRoutePublic = () => {
  return publicRoutes.some(route => regexCompareTheRoutes(route.path, window.location.pathname));
}

export const isServiceAccountRoute = (route) => {
  return serviceAccountRoutes.includes(route);
}
export const isCurrentRouteServiceRoute = () => {
  return serviceAccountRoutes.some(route => regexCompareTheRoutes(route.path, window.location.pathname));
}

export const isQrCodeRelatedRoute = () => {
  const isQrCodeRoute = window.location.pathname.includes('qr-code');

  return isQrCodeRoute;
};