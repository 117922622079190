import { resolveRequestUM } from "./apiUM";
import userManagementApi from "./userManagementApi";
import notificationGroupApi from "./notificationGroupApi";
import userAuthApi from "./userAuth";
import surveyApi from "./feedbackManagement";
import leaderBoardApis from "./apiLB";

export default {
  resolveRequestUM,
  userManagementApi,
  userAuthApi,
  notificationGroupApi,
  surveyApi,
  leaderBoardApis,
};
export {
  resolveRequestUM,
  userManagementApi,
  userAuthApi,
  notificationGroupApi,
  surveyApi,
  leaderBoardApis,
};
