import request from './api'
import requestV2 from './apiv2'

const facilityApi = {
  getFacilities: () => request.get('/fm/facility/fetch'),
  createFacility: (data) => request.post('/fm/facility/create', data),
  updateFacility: (data) => request.post('/fm/facility/create', data),
  deleteFacility: (facilityId) => request.delete(`/fm/facility/${facilityId}`),
  createDepartment: (data) => request.post('/fm/department/create', data),
  deleteDepartment: (departmentId) => request.delete(`/fm/department/${departmentId}`),
  updateDepartment: (data) => request.post('/fm/department/create', data),
  createLocation: (data) => request.post('/fm/location/create', data),
  updateLocation: (data) => request.post('/fm/location/create', data),
  deleteLocation: (locationId) => request.delete(`/fm/location/${locationId}`),
  getLevels: () => requestV2.get('/app/org/levels'),
  getDefinition: () => requestV2.get('/app/org/definition'),
  createSubarea: (data) => request.post('/fm/subarea/create', data),
  updateSubarea: (data) => request.post('/fm/subarea/create', data),
  deleteSubarea: (subareaId) => request.delete(`/fm/subarea/${subareaId}`),
}

export default facilityApi