import React, { createContext, useState, useEffect } from "react";
import jwtDecode from "jwt-decode";

const token = localStorage.getItem("token");

export const SidebarContext = createContext();

const SidebarProvider = (props) => {
  const [raiseDraft, setRaiseDraft] = useState(true);
  const [loginId, setLoginId] = useState("");
  const [raiseDraftTemp, setRaiseDraftTemp] = useState(false);
  const [dataChange, setDataChange] = useState(false);
  useEffect(() => {
    token && setLoginId(jwtDecode(token)["userId"]);
  }, [token]);

  return (
    <SidebarContext.Provider
      value={{
        raiseDraft,
        setRaiseDraft,
        loginId,
        raiseDraftTemp,
        setRaiseDraftTemp,
        dataChange,
        setDataChange,
      }}
    >
      {props.children}
    </SidebarContext.Provider>
  );
};

export default SidebarProvider;
