import request from "../api/apiv2";
import requestUM from "./apiUM";
// const prefix = "apiUM"
const userAuthApi = {
  // getEmployees: () => requestUM.get(prefix + "/aum/um/"),
  getAuth: (idToken, authType) => {
    if(!idToken){
      return
    }
    return requestUM.getAuth(`/aum/${authType || 'auth'}/${idToken}`)
  },
  // getAuth: (idToken) => console.log(`/aum/auth/${idToken}`),
  qrCodeLogin: (data) => requestUM.post('/aum/mauth/', data)
};

export default userAuthApi;

export const getLoginMode = async (subDomain) => request.get(`tenant/scheme?subdomain=${subDomain}`).then(res => res.data)