import userpic from "@iso/assets/images/user1.png";

const profileData = {
  id: 1,
  name: "Samwell Martina",
  username: "@samwell_martina",
  avatar: userpic,
};

export default profileData;
