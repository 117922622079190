import { getAppEnv } from "@iso/utils/helpers";
import * as Sentry from "@sentry/react";

export const initSentry = () => {
  const env = getAppEnv();

  const commonConfig = {
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^\//],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  }

  if (env.isDev) {
    Sentry.init({
      ...commonConfig,
      dsn: "https://f090004bc78ee172a7cbcd08ec13c3ef@o4507366136807424.ingest.de.sentry.io/4507366947356752",
    });
  } else if (env.isProd) {
    Sentry.init({
      ...commonConfig,
      dsn: "https://1790ad0c1d4d6b825c879f9a0430f2a8@o4507366136807424.ingest.de.sentry.io/4507366943227985",
    });
  }
}