const { useMemo } = require("react");
const { default: useCustomLocalTranslation } = require("./useCustomLocalTranslation");
const { safeJsonParse } = require("@iso/utils/helpers");

const useAppTheme = () => {
  const clt = useCustomLocalTranslation();
  const configText = clt("app_theme");

  const config = useMemo(() => {
    return safeJsonParse(configText);
  }, [configText]);

  return config;
}

export default useAppTheme;