// websocketUtils.js
import { Client } from '@stomp/stompjs';
import SockJS from 'sockjs-client';

export const createStompClient = (sockjsURL, connectHeaders) => {
	const client = new Client({
		connectHeaders,
		debug: (str) => {
			console.debug(str);
		},
		reconnectDelay: 5000,
		heartbeatIncoming: 4000,
		heartbeatOutgoing: 4000,
		webSocketFactory: () => new SockJS(sockjsURL),
	});

	return client;
};

export const connectStompClient = (client, onConnectCallback, onErrorCallback) => {
	client.onConnect = onConnectCallback;
	client.onStompError = onErrorCallback;
	client.onClose = () => {
		console.log('Connection closed');
	};
	client.activate();
};

export const disconnectStompClient = (client) => {
	if (client) {
		client.deactivate();
	}
};

export const subscribeToTopic = (client, destination, callback) => {
	if (client && client.connected) {
		return client.subscribe(destination, callback);
	}
};

export const sendMessageToTopic = (client, destination, body, token) => {
	if (client && client.connected) {
		client.publish({
			headers: {
				authorization: token
			},
			destination,
			body
		});
	}
};
