import React, { lazy, Suspense, useEffect } from "react";
import {
  Route,
  Redirect,
  BrowserRouter as Router,
  Switch,
} from "react-router-dom";
import { useSelector } from "react-redux";

import ErrorBoundary from "./ErrorBoundary";
import { publicRoutes } from "./route.constants";
import Loader from "@iso/components/utility/loader";
import jwtDecode from "jwt-decode";
import { useLocation } from "react-router-dom";
const Dashboard = lazy(() => import("./containers/Dashboard/Dashboard"));

function PrivateRoute({ children, ...rest }) {
  // const isLoggedIn = useSelector((state) => state.Auth.idToken);
  const isLoggedIn = localStorage.getItem("loggedIN");
  if (!isLoggedIn) {
    sessionStorage.setItem("recentURL", window.location.pathname);
  }

  const { pathname } = useLocation();

  const APP_ID = "t0t8d38x";

  useEffect(() => {
    const token = localStorage.getItem("token");
    const decodedToken = (token && jwtDecode(token)) || {};
    if (isLoggedIn && decodedToken) {
      const intercomSettings = {
        app_id: APP_ID,
        name: `${decodedToken["firstname"]} ${decodedToken["lastname"]}`, // Full name
        email: decodedToken["email"], // Email address
        user_id: `${decodedToken["tenant"]}.${decodedToken["userId"]}`,
        SQ_User: true,
        alignment: "left",
      };

      window.Intercom("boot", intercomSettings);
    }
  }, [isLoggedIn]);

  if (pathname.includes("/safety-qube")) {
    return <Redirect to={pathname.replace("/safety-qube", "")} />
  }

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isLoggedIn ? (
          children
        ) : (
          <Redirect
            to={{
              // pathname: "/signin",
              pathname: "/signin",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

export default function Routes() {
  return (
    <ErrorBoundary>
      <Suspense fallback={<Loader />}>
        <Router>
          <Switch>
            {publicRoutes.map((route, index) => (
              <Route key={index} path={route.path} exact={route.exact}>
                <route.component />
              </Route>
            ))}
            <PrivateRoute path="/">
              <Dashboard />
            </PrivateRoute>
          </Switch>
        </Router>
      </Suspense>
    </ErrorBoundary>
  );
}
