export default {
  siteName: '../assets/images/Logo.png',
  siteShort:"SQ",
  footerText: `Safety-Qube @ ${new Date().getFullYear()} Created by RedQ, Inc`,
  enableAnimatedRoute: false,
  // apiUrl: 'http://yoursite.com/api/',
  // google: {
  //   analyticsKey: 'UA-xxxxxxxxx-1',
  // },
  dashboard: '/',
};


export const SERVICE_ACCOUNT = "JTdCJTIycGFzc3dvcmQlMjIlM0ElMjJWMlZzWTI5dFpVQXhNak0lM0QlMjIlMkMlMjJ1c2VybmFtZSUyMiUzQSUyMnNhZmV0eXF1YmUuYW5vbiU0MHNhZmV0eXF1YmUuY29tJTIyJTdE";