export const timezones = [
  {
      "label": "Pacific/Midway",
      "tzCode": "Pacific/Midway",
      "name": "Midway",
      "utc": "-11:00"
  },
  {
      "label": "Pacific/Niue",
      "tzCode": "Pacific/Niue",
      "name": "Alofi",
      "utc": "-11:00"
  },
  {
      "label": "Pacific/Pago_Pago",
      "tzCode": "Pacific/Pago_Pago",
      "name": "Pago Pago, Tāfuna, Ta`ū, Taulaga",
      "utc": "-11:00"
  },
  {
      "label": "America/Adak",
      "tzCode": "America/Adak",
      "name": "Adak",
      "utc": "-10:00"
  },
  {
      "label": "Pacific/Honolulu",
      "tzCode": "Pacific/Honolulu",
      "name": "Honolulu, East Honolulu, Pearl City, Hilo, Kailua",
      "utc": "-10:00"
  },
  {
      "label": "Pacific/Rarotonga",
      "tzCode": "Pacific/Rarotonga",
      "name": "Avarua",
      "utc": "-10:00"
  },
  {
      "label": "Pacific/Tahiti",
      "tzCode": "Pacific/Tahiti",
      "name": "Faaa, Papeete, Punaauia, Pirae, Mahina",
      "utc": "-10:00"
  },
  {
      "label": "Pacific/Marquesas",
      "tzCode": "Pacific/Marquesas",
      "name": "Taiohae",
      "utc": "-09:30"
  },
  {
      "label": "America/Anchorage",
      "tzCode": "America/Anchorage",
      "name": "Anchorage, Fairbanks, Eagle River, Badger, Knik-Fairview",
      "utc": "-09:00"
  },
  {
      "label": "America/Juneau",
      "tzCode": "America/Juneau",
      "name": "Juneau",
      "utc": "-09:00"
  },
  {
      "label": "America/Metlakatla",
      "tzCode": "America/Metlakatla",
      "name": "Metlakatla",
      "utc": "-09:00"
  },
  {
      "label": "America/Nome",
      "tzCode": "America/Nome",
      "name": "Nome",
      "utc": "-09:00"
  },
  {
      "label": "America/Sitka",
      "tzCode": "America/Sitka",
      "name": "Sitka, Ketchikan",
      "utc": "-09:00"
  },
  {
      "label": "America/Yakutat",
      "tzCode": "America/Yakutat",
      "name": "Yakutat",
      "utc": "-09:00"
  },
  {
      "label": "Pacific/Gambier",
      "tzCode": "Pacific/Gambier",
      "name": "Gambier",
      "utc": "-09:00"
  },
  {
      "label": "America/Los_Angeles",
      "tzCode": "America/Los_Angeles",
      "name": "Los Angeles, San Diego, San Jose, San Francisco, Seattle",
      "utc": "-08:00"
  },
  {
      "label": "America/Tijuana",
      "tzCode": "America/Tijuana",
      "name": "Tijuana, Mexicali, Ensenada, Rosarito, Tecate",
      "utc": "-08:00"
  },
  {
      "label": "America/Vancouver",
      "tzCode": "America/Vancouver",
      "name": "Vancouver, Surrey, Okanagan, Victoria, Burnaby",
      "utc": "-08:00"
  },
  {
      "label": "Pacific/Pitcairn",
      "tzCode": "Pacific/Pitcairn",
      "name": "Adamstown",
      "utc": "-08:00"
  },
  {
      "label": "America/Boise",
      "tzCode": "America/Boise",
      "name": "Boise, Meridian, Nampa, Idaho Falls, Pocatello",
      "utc": "-07:00"
  },
  {
      "label": "America/Cambridge_Bay",
      "tzCode": "America/Cambridge_Bay",
      "name": "Cambridge Bay",
      "utc": "-07:00"
  },
  {
      "label": "America/Chihuahua",
      "tzCode": "America/Chihuahua",
      "name": "Chihuahua, Ciudad Delicias, Cuauhtémoc, Parral, Nuevo Casas Grandes",
      "utc": "-07:00"
  },
  {
      "label": "America/Creston",
      "tzCode": "America/Creston",
      "name": "Creston",
      "utc": "-07:00"
  },
  {
      "label": "America/Dawson",
      "tzCode": "America/Dawson",
      "name": "Dawson",
      "utc": "-07:00"
  },
  {
      "label": "America/Dawson_Creek",
      "tzCode": "America/Dawson_Creek",
      "name": "Fort St. John, Dawson Creek",
      "utc": "-07:00"
  },
  {
      "label": "America/Denver",
      "tzCode": "America/Denver",
      "name": "Denver, El Paso, Albuquerque, Colorado Springs, Aurora",
      "utc": "-07:00"
  },
  {
      "label": "America/Edmonton",
      "tzCode": "America/Edmonton",
      "name": "Calgary, Edmonton, Fort McMurray, Red Deer, Lethbridge",
      "utc": "-07:00"
  },
  {
      "label": "America/Fort_Nelson",
      "tzCode": "America/Fort_Nelson",
      "name": "Fort Nelson",
      "utc": "-07:00"
  },
  {
      "label": "America/Hermosillo",
      "tzCode": "America/Hermosillo",
      "name": "Hermosillo, Ciudad Obregón, Nogales, San Luis Río Colorado, Navojoa",
      "utc": "-07:00"
  },
  {
      "label": "America/Inuvik",
      "tzCode": "America/Inuvik",
      "name": "Inuvik",
      "utc": "-07:00"
  },
  {
      "label": "America/Mazatlan",
      "tzCode": "America/Mazatlan",
      "name": "Culiacán, Mazatlán, Tepic, Los Mochis, La Paz",
      "utc": "-07:00"
  },
  {
      "label": "America/Ojinaga",
      "tzCode": "America/Ojinaga",
      "name": "Ciudad Juárez, Manuel Ojinaga, Ojinaga",
      "utc": "-07:00"
  },
  {
      "label": "America/Phoenix",
      "tzCode": "America/Phoenix",
      "name": "Phoenix, Tucson, Mesa, Chandler, Gilbert",
      "utc": "-07:00"
  },
  {
      "label": "America/Whitehorse",
      "tzCode": "America/Whitehorse",
      "name": "Whitehorse",
      "utc": "-07:00"
  },
  {
      "label": "America/Yellowknife",
      "tzCode": "America/Yellowknife",
      "name": "Yellowknife",
      "utc": "-07:00"
  },
  {
      "label": "America/Bahia_Banderas",
      "tzCode": "America/Bahia_Banderas",
      "name": "Mezcales, San Vicente, Bucerías, Valle de Banderas",
      "utc": "-06:00"
  },
  {
      "label": "America/Belize",
      "tzCode": "America/Belize",
      "name": "Belize City, San Ignacio, Orange Walk, Belmopan, Dangriga",
      "utc": "-06:00"
  },
  {
      "label": "America/Chicago",
      "tzCode": "America/Chicago",
      "name": "Chicago, Houston, San Antonio, Dallas, Austin",
      "utc": "-06:00"
  },
  {
      "label": "America/Costa_Rica",
      "tzCode": "America/Costa_Rica",
      "name": "San José, Limón, San Francisco, Alajuela, Liberia",
      "utc": "-06:00"
  },
  {
      "label": "America/El_Salvador",
      "tzCode": "America/El_Salvador",
      "name": "San Salvador, Soyapango, Santa Ana, San Miguel, Mejicanos",
      "utc": "-06:00"
  },
  {
      "label": "America/Guatemala",
      "tzCode": "America/Guatemala",
      "name": "Guatemala City, Mixco, Villa Nueva, Petapa, San Juan Sacatepéquez",
      "utc": "-06:00"
  },
  {
      "label": "America/Indiana/Knox",
      "tzCode": "America/Indiana/Knox",
      "name": "Knox",
      "utc": "-06:00"
  },
  {
      "label": "America/Indiana/Tell_City",
      "tzCode": "America/Indiana/Tell_City",
      "name": "Tell City",
      "utc": "-06:00"
  },
  {
      "label": "America/Managua",
      "tzCode": "America/Managua",
      "name": "Managua, León, Masaya, Chinandega, Matagalpa",
      "utc": "-06:00"
  },
  {
      "label": "America/Matamoros",
      "tzCode": "America/Matamoros",
      "name": "Reynosa, Heroica Matamoros, Nuevo Laredo, Piedras Negras, Ciudad Acuña",
      "utc": "-06:00"
  },
  {
      "label": "America/Menominee",
      "tzCode": "America/Menominee",
      "name": "Menominee, Iron Mountain, Kingsford, Ironwood, Iron River",
      "utc": "-06:00"
  },
  {
      "label": "America/Merida",
      "tzCode": "America/Merida",
      "name": "Mérida, Campeche, Ciudad del Carmen, Kanasín, Valladolid",
      "utc": "-06:00"
  },
  {
      "label": "America/Mexico_City",
      "tzCode": "America/Mexico_City",
      "name": "Mexico City, Iztapalapa, Ecatepec de Morelos, Guadalajara, Puebla",
      "utc": "-06:00"
  },
  {
      "label": "America/Monterrey",
      "tzCode": "America/Monterrey",
      "name": "Monterrey, Saltillo, Guadalupe, Torreón, Victoria de Durango",
      "utc": "-06:00"
  },
  {
      "label": "America/North_Dakota/Beulah",
      "tzCode": "America/North_Dakota/Beulah",
      "name": "Beulah",
      "utc": "-06:00"
  },
  {
      "label": "America/North_Dakota/Center",
      "tzCode": "America/North_Dakota/Center",
      "name": "Center",
      "utc": "-06:00"
  },
  {
      "label": "America/North_Dakota/New_Salem",
      "tzCode": "America/North_Dakota/New_Salem",
      "name": "Mandan",
      "utc": "-06:00"
  },
  {
      "label": "America/Rainy_River",
      "tzCode": "America/Rainy_River",
      "name": "Rainy River",
      "utc": "-06:00"
  },
  {
      "label": "America/Rankin_Inlet",
      "tzCode": "America/Rankin_Inlet",
      "name": "Rankin Inlet",
      "utc": "-06:00"
  },
  {
      "label": "America/Regina",
      "tzCode": "America/Regina",
      "name": "Saskatoon, Regina, Prince Albert, Moose Jaw, North Battleford",
      "utc": "-06:00"
  },
  {
      "label": "America/Resolute",
      "tzCode": "America/Resolute",
      "name": "Resolute",
      "utc": "-06:00"
  },
  {
      "label": "America/Swift_Current",
      "tzCode": "America/Swift_Current",
      "name": "Swift Current",
      "utc": "-06:00"
  },
  {
      "label": "America/Tegucigalpa",
      "tzCode": "America/Tegucigalpa",
      "name": "Tegucigalpa, San Pedro Sula, Choloma, La Ceiba, El Progreso",
      "utc": "-06:00"
  },
  {
      "label": "America/Winnipeg",
      "tzCode": "America/Winnipeg",
      "name": "Winnipeg, Brandon, Kenora, Portage la Prairie, Thompson",
      "utc": "-06:00"
  },
  {
      "label": "Pacific/Easter",
      "tzCode": "Pacific/Easter",
      "name": "Easter",
      "utc": "-06:00"
  },
  {
      "label": "Pacific/Galapagos",
      "tzCode": "Pacific/Galapagos",
      "name": "Puerto Ayora, Puerto Baquerizo Moreno",
      "utc": "-06:00"
  },
  {
      "label": "America/Atikokan",
      "tzCode": "America/Atikokan",
      "name": "Atikokan",
      "utc": "-05:00"
  },
  {
      "label": "America/Bogota",
      "tzCode": "America/Bogota",
      "name": "Bogotá, Cali, Medellín, Barranquilla, Cartagena",
      "utc": "-05:00"
  },
  {
      "label": "America/Cancun",
      "tzCode": "America/Cancun",
      "name": "Cancún, Chetumal, Playa del Carmen, Cozumel, Felipe Carrillo Puerto",
      "utc": "-05:00"
  },
  {
      "label": "America/Cayman",
      "tzCode": "America/Cayman",
      "name": "George Town, West Bay, Bodden Town, East End, North Side",
      "utc": "-05:00"
  },
  {
      "label": "America/Detroit",
      "tzCode": "America/Detroit",
      "name": "Detroit, Grand Rapids, Warren, Sterling Heights, Ann Arbor",
      "utc": "-05:00"
  },
  {
      "label": "America/Eirunepe",
      "tzCode": "America/Eirunepe",
      "name": "Eirunepé, Benjamin Constant, Envira",
      "utc": "-05:00"
  },
  {
      "label": "America/Grand_Turk",
      "tzCode": "America/Grand_Turk",
      "name": "Cockburn Town",
      "utc": "-05:00"
  },
  {
      "label": "America/Guayaquil",
      "tzCode": "America/Guayaquil",
      "name": "Guayaquil, Quito, Cuenca, Santo Domingo de los Colorados, Machala",
      "utc": "-05:00"
  },
  {
      "label": "America/Havana",
      "tzCode": "America/Havana",
      "name": "Havana, Santiago de Cuba, Camagüey, Holguín, Guantánamo",
      "utc": "-05:00"
  },
  {
      "label": "America/Indiana/Indianapolis",
      "tzCode": "America/Indiana/Indianapolis",
      "name": "Indianapolis, Fort Wayne, South Bend, Carmel, Bloomington",
      "utc": "-05:00"
  },
  {
      "label": "America/Indiana/Marengo",
      "tzCode": "America/Indiana/Marengo",
      "name": "Marengo",
      "utc": "-05:00"
  },
  {
      "label": "America/Indiana/Petersburg",
      "tzCode": "America/Indiana/Petersburg",
      "name": "Petersburg",
      "utc": "-05:00"
  },
  {
      "label": "America/Indiana/Vevay",
      "tzCode": "America/Indiana/Vevay",
      "name": "Vevay",
      "utc": "-05:00"
  },
  {
      "label": "America/Indiana/Vincennes",
      "tzCode": "America/Indiana/Vincennes",
      "name": "Vincennes, Jasper, Washington, Huntingburg",
      "utc": "-05:00"
  },
  {
      "label": "America/Indiana/Winamac",
      "tzCode": "America/Indiana/Winamac",
      "name": "Winamac",
      "utc": "-05:00"
  },
  {
      "label": "America/Iqaluit",
      "tzCode": "America/Iqaluit",
      "name": "Iqaluit",
      "utc": "-05:00"
  },
  {
      "label": "America/Jamaica",
      "tzCode": "America/Jamaica",
      "name": "Kingston, New Kingston, Spanish Town, Portmore, Montego Bay",
      "utc": "-05:00"
  },
  {
      "label": "America/Kentucky/Louisville",
      "tzCode": "America/Kentucky/Louisville",
      "name": "Louisville, Jeffersonville, New Albany, Jeffersontown, Pleasure Ridge Park",
      "utc": "-05:00"
  },
  {
      "label": "America/Kentucky/Monticello",
      "tzCode": "America/Kentucky/Monticello",
      "name": "Monticello",
      "utc": "-05:00"
  },
  {
      "label": "America/Lima",
      "tzCode": "America/Lima",
      "name": "Lima, Arequipa, Callao, Trujillo, Chiclayo",
      "utc": "-05:00"
  },
  {
      "label": "America/Nassau",
      "tzCode": "America/Nassau",
      "name": "Nassau, Lucaya, Freeport, West End, Cooper’s Town",
      "utc": "-05:00"
  },
  {
      "label": "America/New_York",
      "tzCode": "America/New_York",
      "name": "New York City, Brooklyn, Queens, Philadelphia, Manhattan",
      "utc": "-05:00"
  },
  {
      "label": "America/Nipigon",
      "tzCode": "America/Nipigon",
      "name": "Nipigon",
      "utc": "-05:00"
  },
  {
      "label": "America/Panama",
      "tzCode": "America/Panama",
      "name": "Panamá, San Miguelito, Juan Díaz, David, Arraiján",
      "utc": "-05:00"
  },
  {
      "label": "America/Pangnirtung",
      "tzCode": "America/Pangnirtung",
      "name": "Pangnirtung",
      "utc": "-05:00"
  },
  {
      "label": "America/Port-au-Prince",
      "tzCode": "America/Port-au-Prince",
      "name": "Port-au-Prince, Carrefour, Delmas 73, Pétionville, Port-de-Paix",
      "utc": "-05:00"
  },
  {
      "label": "America/Rio_Branco",
      "tzCode": "America/Rio_Branco",
      "name": "Rio Branco, Cruzeiro do Sul, Sena Madureira, Tarauacá, Feijó",
      "utc": "-05:00"
  },
  {
      "label": "America/Thunder_Bay",
      "tzCode": "America/Thunder_Bay",
      "name": "Thunder Bay",
      "utc": "-05:00"
  },
  {
      "label": "America/Toronto",
      "tzCode": "America/Toronto",
      "name": "Toronto, Montréal, Ottawa, Mississauga, Québec",
      "utc": "-05:00"
  },
  {
      "label": "America/AnguillaSandy Hill",
      "tzCode": "America/AnguillaSandy Hill",
      "name": "The Valley, Blowing Point Village, Sandy Ground Village, The Quarter, Sandy Hill",
      "utc": "-04:00"
  },
  {
      "label": "America/Antigua",
      "tzCode": "America/Antigua",
      "name": "Saint John’s, Piggotts, Bolands, Codrington, Parham",
      "utc": "-04:00"
  },
  {
      "label": "America/Aruba",
      "tzCode": "America/Aruba",
      "name": "Oranjestad, Tanki Leendert, San Nicolas, Santa Cruz, Paradera",
      "utc": "-04:00"
  },
  {
      "label": "America/Asuncion",
      "tzCode": "America/Asuncion",
      "name": "Asunción, Ciudad del Este, San Lorenzo, Capiatá, Lambaré",
      "utc": "-04:00"
  },
  {
      "label": "America/Barbados",
      "tzCode": "America/Barbados",
      "name": "Bridgetown, Speightstown, Oistins, Bathsheba, Holetown",
      "utc": "-04:00"
  },
  {
      "label": "America/Blanc-Sablon",
      "tzCode": "America/Blanc-Sablon",
      "name": "Lévis",
      "utc": "-04:00"
  },
  {
      "label": "America/Boa_Vista",
      "tzCode": "America/Boa_Vista",
      "name": "Boa Vista",
      "utc": "-04:00"
  },
  {
      "label": "America/Campo_Grande",
      "tzCode": "America/Campo_Grande",
      "name": "Campo Grande, Dourados, Corumbá, Três Lagoas, Ponta Porã",
      "utc": "-04:00"
  },
  {
      "label": "America/Caracas",
      "tzCode": "America/Caracas",
      "name": "Caracas, Maracaibo, Maracay, Valencia, Barquisimeto",
      "utc": "-04:00"
  },
  {
      "label": "America/Cuiaba",
      "tzCode": "America/Cuiaba",
      "name": "Cuiabá, Várzea Grande, Rondonópolis, Sinop, Barra do Garças",
      "utc": "-04:00"
  },
  {
      "label": "America/Curacao",
      "tzCode": "America/Curacao",
      "name": "Willemstad, Sint Michiel Liber",
      "utc": "-04:00"
  },
  {
      "label": "America/Dominica",
      "tzCode": "America/Dominica",
      "name": "Roseau, Portsmouth, Berekua, Saint Joseph, Wesley",
      "utc": "-04:00"
  },
  {
      "label": "America/Glace_Bay",
      "tzCode": "America/Glace_Bay",
      "name": "Sydney, Glace Bay, Sydney Mines",
      "utc": "-04:00"
  },
  {
      "label": "America/Goose_Bay",
      "tzCode": "America/Goose_Bay",
      "name": "Labrador City, Happy Valley-Goose Bay",
      "utc": "-04:00"
  },
  {
      "label": "America/Grenada",
      "tzCode": "America/Grenada",
      "name": "Saint George's, Gouyave, Grenville, Victoria, Saint David’s",
      "utc": "-04:00"
  },
  {
      "label": "America/Guadeloupe",
      "tzCode": "America/Guadeloupe",
      "name": "Les Abymes, Baie-Mahault, Le Gosier, Petit-Bourg, Sainte-Anne",
      "utc": "-04:00"
  },
  {
      "label": "America/Guyana",
      "tzCode": "America/Guyana",
      "name": "Georgetown, Linden, New Amsterdam, Anna Regina, Bartica",
      "utc": "-04:00"
  },
  {
      "label": "America/Halifax",
      "tzCode": "America/Halifax",
      "name": "Halifax, Dartmouth, Charlottetown, Lower Sackville, Truro",
      "utc": "-04:00"
  },
  {
      "label": "America/Kralendijk",
      "tzCode": "America/Kralendijk",
      "name": "Kralendijk, Oranjestad, The Bottom",
      "utc": "-04:00"
  },
  {
      "label": "America/La_Paz",
      "tzCode": "America/La_Paz",
      "name": "Santa Cruz de la Sierra, Cochabamba, La Paz, Sucre, Oruro",
      "utc": "-04:00"
  },
  {
      "label": "America/Lower_Princes",
      "tzCode": "America/Lower_Princes",
      "name": "Cul de Sac, Lower Prince’s Quarter, Koolbaai, Philipsburg",
      "utc": "-04:00"
  },
  {
      "label": "America/Manaus",
      "tzCode": "America/Manaus",
      "name": "Manaus, Itacoatiara, Parintins, Manacapuru, Coari",
      "utc": "-04:00"
  },
  {
      "label": "America/Marigot",
      "tzCode": "America/Marigot",
      "name": "Marigot",
      "utc": "-04:00"
  },
  {
      "label": "America/Martinique",
      "tzCode": "America/Martinique",
      "name": "Fort-de-France, Le Lamentin, Le Robert, Sainte-Marie, Le François",
      "utc": "-04:00"
  },
  {
      "label": "America/Moncton",
      "tzCode": "America/Moncton",
      "name": "Moncton, Saint John, Fredericton, Dieppe, Miramichi",
      "utc": "-04:00"
  },
  {
      "label": "America/Montserrat",
      "tzCode": "America/Montserrat",
      "name": "Brades, Saint Peters, Plymouth",
      "utc": "-04:00"
  },
  {
      "label": "America/Porto_Velho",
      "tzCode": "America/Porto_Velho",
      "name": "Porto Velho, Ji Paraná, Vilhena, Ariquemes, Cacoal",
      "utc": "-04:00"
  },
  {
      "label": "America/Port_of_Spain",
      "tzCode": "America/Port_of_Spain",
      "name": "Chaguanas, Mon Repos, San Fernando, Port of Spain, Rio Claro",
      "utc": "-04:00"
  },
  {
      "label": "America/Puerto_Rico",
      "tzCode": "America/Puerto_Rico",
      "name": "San Juan, Bayamón, Carolina, Ponce, Caguas",
      "utc": "-04:00"
  },
  {
      "label": "America/Santiago",
      "tzCode": "America/Santiago",
      "name": "Santiago, Puente Alto, Antofagasta, Viña del Mar, Valparaíso",
      "utc": "-04:00"
  },
  {
      "label": "America/Santo_Domingo",
      "tzCode": "America/Santo_Domingo",
      "name": "Santo Domingo, Santiago de los Caballeros, Santo Domingo Oeste, Santo Domingo Este, San Pedro de Macorís",
      "utc": "-04:00"
  },
  {
      "label": "America/St_Barthelemy",
      "tzCode": "America/St_Barthelemy",
      "name": "Gustavia",
      "utc": "-04:00"
  },
  {
      "label": "America/St_Kitts",
      "tzCode": "America/St_Kitts",
      "name": "Basseterre, Fig Tree, Market Shop, Saint Paul’s, Middle Island",
      "utc": "-04:00"
  },
  {
      "label": "America/St_Lucia",
      "tzCode": "America/St_Lucia",
      "name": "Castries, Bisee, Vieux Fort, Micoud, Soufrière",
      "utc": "-04:00"
  },
  {
      "label": "America/St_Thomas",
      "tzCode": "America/St_Thomas",
      "name": "Saint Croix, Charlotte Amalie, Cruz Bay",
      "utc": "-04:00"
  },
  {
      "label": "America/St_Vincent",
      "tzCode": "America/St_Vincent",
      "name": "Kingstown, Kingstown Park, Georgetown, Barrouallie, Port Elizabeth",
      "utc": "-04:00"
  },
  {
      "label": "America/Thule",
      "tzCode": "America/Thule",
      "name": "Thule",
      "utc": "-04:00"
  },
  {
      "label": "America/Tortola",
      "tzCode": "America/Tortola",
      "name": "Road Town",
      "utc": "-04:00"
  },
  {
      "label": "Atlantic/Bermuda",
      "tzCode": "Atlantic/Bermuda",
      "name": "Hamilton",
      "utc": "-04:00"
  },
  {
      "label": "America/St_Johns",
      "tzCode": "America/St_Johns",
      "name": "St. John's, Mount Pearl, Corner Brook, Conception Bay South, Bay Roberts",
      "utc": "-03:30"
  },
  {
      "label": "America/Araguaina",
      "tzCode": "America/Araguaina",
      "name": "Palmas, Araguaína, Gurupi, Miracema do Tocantins, Porto Franco",
      "utc": "-03:00"
  },
  {
      "label": "America/Argentina/Buenos_Aires",
      "tzCode": "America/Argentina/Buenos_Aires",
      "name": "Buenos Aires, La Plata, Mar del Plata, Morón, Bahía Blanca",
      "utc": "-03:00"
  },
  {
      "label": "America/Argentina/Catamarca",
      "tzCode": "America/Argentina/Catamarca",
      "name": "San Fernando del Valle de Catamarca, Trelew, Puerto Madryn, Esquel, Rawson",
      "utc": "-03:00"
  },
  {
      "label": "America/Argentina/Cordoba",
      "tzCode": "America/Argentina/Cordoba",
      "name": "Córdoba, Rosario, Santa Fe, Resistencia, Santiago del Estero",
      "utc": "-03:00"
  },
  {
      "label": "America/Argentina/Jujuy",
      "tzCode": "America/Argentina/Jujuy",
      "name": "San Salvador de Jujuy, San Pedro de Jujuy, Libertador General San Martín, Palpalá, La Quiaca",
      "utc": "-03:00"
  },
  {
      "label": "America/Argentina/La_Rioja",
      "tzCode": "America/Argentina/La_Rioja",
      "name": "La Rioja, Chilecito, Arauco, Chamical",
      "utc": "-03:00"
  },
  {
      "label": "America/Argentina/Mendoza",
      "tzCode": "America/Argentina/Mendoza",
      "name": "Mendoza, San Rafael, San Martín",
      "utc": "-03:00"
  },
  {
      "label": "America/Argentina/Rio_Gallegos",
      "tzCode": "America/Argentina/Rio_Gallegos",
      "name": "Comodoro Rivadavia, Río Gallegos, Caleta Olivia, Pico Truncado, Puerto Deseado",
      "utc": "-03:00"
  },
  {
      "label": "America/Argentina/Salta",
      "tzCode": "America/Argentina/Salta",
      "name": "Salta, Neuquén, Santa Rosa, San Carlos de Bariloche, Cipolletti",
      "utc": "-03:00"
  },
  {
      "label": "America/Argentina/San_Juan",
      "tzCode": "America/Argentina/San_Juan",
      "name": "San Juan, Chimbas, Santa Lucía, Pocito, Caucete",
      "utc": "-03:00"
  },
  {
      "label": "America/Argentina/San_Luis",
      "tzCode": "America/Argentina/San_Luis",
      "name": "San Luis, Villa Mercedes, La Punta, Merlo, Justo Daract",
      "utc": "-03:00"
  },
  {
      "label": "America/Argentina/Tucuman",
      "tzCode": "America/Argentina/Tucuman",
      "name": "San Miguel de Tucumán, Yerba Buena, Tafí Viejo, Alderetes, Aguilares",
      "utc": "-03:00"
  },
  {
      "label": "America/Argentina/Ushuaia",
      "tzCode": "America/Argentina/Ushuaia",
      "name": "Ushuaia, Río Grande",
      "utc": "-03:00"
  },
  {
      "label": "America/Bahia",
      "tzCode": "America/Bahia",
      "name": "Salvador, Feira de Santana, Vitória da Conquista, Itabuna, Camaçari",
      "utc": "-03:00"
  },
  {
      "label": "America/Belem",
      "tzCode": "America/Belem",
      "name": "Belém, Ananindeua, Macapá, Parauapebas, Marabá",
      "utc": "-03:00"
  },
  {
      "label": "America/Cayenne",
      "tzCode": "America/Cayenne",
      "name": "Cayenne, Matoury, Saint-Laurent-du-Maroni, Kourou, Rémire-Montjoly",
      "utc": "-03:00"
  },
  {
      "label": "America/Fortaleza",
      "tzCode": "America/Fortaleza",
      "name": "Fortaleza, São Luís, Natal, Teresina, João Pessoa",
      "utc": "-03:00"
  },
  {
      "label": "America/Godthab",
      "tzCode": "America/Godthab",
      "name": "Nuuk, Sisimiut, Ilulissat, Qaqortoq, Aasiaat",
      "utc": "-03:00"
  },
  {
      "label": "America/Maceio",
      "tzCode": "America/Maceio",
      "name": "Maceió, Aracaju, Arapiraca, Nossa Senhora do Socorro, São Cristóvão",
      "utc": "-03:00"
  },
  {
      "label": "America/Miquelon",
      "tzCode": "America/Miquelon",
      "name": "Saint-Pierre, Miquelon",
      "utc": "-03:00"
  },
  {
      "label": "America/Montevideo",
      "tzCode": "America/Montevideo",
      "name": "Montevideo, Salto, Paysandú, Las Piedras, Rivera",
      "utc": "-03:00"
  },
  {
      "label": "America/Paramaribo",
      "tzCode": "America/Paramaribo",
      "name": "Paramaribo, Lelydorp, Brokopondo, Nieuw Nickerie, Moengo",
      "utc": "-03:00"
  },
  {
      "label": "America/Punta_Arenas",
      "tzCode": "America/Punta_Arenas",
      "name": "Punta Arenas, Puerto Natales",
      "utc": "-03:00"
  },
  {
      "label": "America/Recife",
      "tzCode": "America/Recife",
      "name": "Recife, Jaboatão, Jaboatão dos Guararapes, Olinda, Paulista",
      "utc": "-03:00"
  },
  {
      "label": "America/Santarem",
      "tzCode": "America/Santarem",
      "name": "Santarém, Altamira, Itaituba, Oriximiná, Alenquer",
      "utc": "-03:00"
  },
  {
      "label": "America/Sao_Paulo",
      "tzCode": "America/Sao_Paulo",
      "name": "São Paulo, Rio de Janeiro, Belo Horizonte, Brasília, Curitiba",
      "utc": "-03:00"
  },
  {
      "label": "Antarctica/Palmer",
      "tzCode": "Antarctica/Palmer",
      "name": "Palmer",
      "utc": "-03:00"
  },
  {
      "label": "Antarctica/Rothera",
      "tzCode": "Antarctica/Rothera",
      "name": "Rothera",
      "utc": "-03:00"
  },
  {
      "label": "Atlantic/Stanley",
      "tzCode": "Atlantic/Stanley",
      "name": "Stanley",
      "utc": "-03:00"
  },
  {
      "label": "America/Noronha",
      "tzCode": "America/Noronha",
      "name": "Itamaracá",
      "utc": "-02:00"
  },
  {
      "label": "Atlantic/South_Georgia",
      "tzCode": "Atlantic/South_Georgia",
      "name": "Grytviken",
      "utc": "-02:00"
  },
  {
      "label": "America/Scoresbysund",
      "tzCode": "America/Scoresbysund",
      "name": "Scoresbysund",
      "utc": "-01:00"
  },
  {
      "label": "Atlantic/Azores",
      "tzCode": "Atlantic/Azores",
      "name": "Ponta Delgada, Lagoa, Angra do Heroísmo, Rosto de Cão, Rabo de Peixe",
      "utc": "-01:00"
  },
  {
      "label": "Atlantic/Cape_Verde",
      "tzCode": "Atlantic/Cape_Verde",
      "name": "Praia, Mindelo, Santa Maria, Cova Figueira, Santa Cruz",
      "utc": "-01:00"
  },
  {
      "label": "Africa/Abidjan",
      "tzCode": "Africa/Abidjan",
      "name": "Abidjan, Abobo, Bouaké, Daloa, San-Pédro",
      "utc": "+00:00"
  },
  {
      "label": "Africa/Accra",
      "tzCode": "Africa/Accra",
      "name": "Accra, Kumasi, Tamale, Takoradi, Atsiaman",
      "utc": "+00:00"
  },
  {
      "label": "Africa/Bamako",
      "tzCode": "Africa/Bamako",
      "name": "Bamako, Sikasso, Mopti, Koutiala, Ségou",
      "utc": "+00:00"
  },
  {
      "label": "Africa/Banjul",
      "tzCode": "Africa/Banjul",
      "name": "Serekunda, Brikama, Bakau, Banjul, Farafenni",
      "utc": "+00:00"
  },
  {
      "label": "Africa/Bissau",
      "tzCode": "Africa/Bissau",
      "name": "Bissau, Bafatá, Gabú, Bissorã, Bolama",
      "utc": "+00:00"
  },
  {
      "label": "Africa/Casablanca",
      "tzCode": "Africa/Casablanca",
      "name": "Casablanca, Rabat, Fès, Sale, Marrakesh",
      "utc": "+00:00"
  },
  {
      "label": "Africa/Conakry",
      "tzCode": "Africa/Conakry",
      "name": "Camayenne, Conakry, Nzérékoré, Kindia, Kankan",
      "utc": "+00:00"
  },
  {
      "label": "Africa/Dakar",
      "tzCode": "Africa/Dakar",
      "name": "Dakar, Pikine, Touba, Thiès, Thiès Nones",
      "utc": "+00:00"
  },
  {
      "label": "Africa/El_Aaiun",
      "tzCode": "Africa/El_Aaiun",
      "name": "Laayoune, Dakhla, Laayoune Plage",
      "utc": "+00:00"
  },
  {
      "label": "Africa/Freetown",
      "tzCode": "Africa/Freetown",
      "name": "Freetown, Bo, Kenema, Koidu, Makeni",
      "utc": "+00:00"
  },
  {
      "label": "Africa/Lome",
      "tzCode": "Africa/Lome",
      "name": "Lomé, Sokodé, Kara, Atakpamé, Kpalimé",
      "utc": "+00:00"
  },
  {
      "label": "Africa/Monrovia",
      "tzCode": "Africa/Monrovia",
      "name": "Monrovia, Gbarnga, Kakata, Bensonville, Harper",
      "utc": "+00:00"
  },
  {
      "label": "Africa/Nouakchott",
      "tzCode": "Africa/Nouakchott",
      "name": "Nouakchott, Nouadhibou, Néma, Kaédi, Rosso",
      "utc": "+00:00"
  },
  {
      "label": "Africa/Ouagadougou",
      "tzCode": "Africa/Ouagadougou",
      "name": "Ouagadougou, Bobo-Dioulasso, Koudougou, Ouahigouya, Banfora",
      "utc": "+00:00"
  },
  {
      "label": "Africa/Sao_Tome",
      "tzCode": "Africa/Sao_Tome",
      "name": "São Tomé, Santo António",
      "utc": "+00:00"
  },
  {
      "label": "America/Danmarkshavn",
      "tzCode": "America/Danmarkshavn",
      "name": "Danmarkshavn",
      "utc": "+00:00"
  },
  {
      "label": "Antarctica/Troll",
      "tzCode": "Antarctica/Troll",
      "name": "Troll",
      "utc": "+00:00"
  },
  {
      "label": "Atlantic/Canary",
      "tzCode": "Atlantic/Canary",
      "name": "Las Palmas de Gran Canaria, Santa Cruz de Tenerife, La Laguna, Telde, Arona",
      "utc": "+00:00"
  },
  {
      "label": "Atlantic/Faroe",
      "tzCode": "Atlantic/Faroe",
      "name": "Tórshavn, Klaksvík, Fuglafjørður, Tvøroyri, Miðvágur",
      "utc": "+00:00"
  },
  {
      "label": "Atlantic/Madeira",
      "tzCode": "Atlantic/Madeira",
      "name": "Funchal, Câmara de Lobos, São Martinho, Caniço, Machico",
      "utc": "+00:00"
  },
  {
      "label": "Atlantic/Reykjavik",
      "tzCode": "Atlantic/Reykjavik",
      "name": "Reykjavík, Kópavogur, Hafnarfjörður, Akureyri, Garðabær",
      "utc": "+00:00"
  },
  {
      "label": "Atlantic/St_Helena",
      "tzCode": "Atlantic/St_Helena",
      "name": "Jamestown, Georgetown, Edinburgh of the Seven Seas",
      "utc": "+00:00"
  },
  {
      "label": "Europe/Dublin",
      "tzCode": "Europe/Dublin",
      "name": "Dublin, Cork, Luimneach, Gaillimh, Tallaght",
      "utc": "+00:00"
  },
  {
      "label": "Europe/Guernsey",
      "tzCode": "Europe/Guernsey",
      "name": "Saint Peter Port, St Martin, Saint Sampson, St Anne, Saint Saviour",
      "utc": "+00:00"
  },
  {
      "label": "Europe/Isle_of_Man",
      "tzCode": "Europe/Isle_of_Man",
      "name": "Douglas, Ramsey, Peel, Port Erin, Castletown",
      "utc": "+00:00"
  },
  {
      "label": "Europe/Jersey",
      "tzCode": "Europe/Jersey",
      "name": "Saint Helier, Le Hocq",
      "utc": "+00:00"
  },
  {
      "label": "Europe/Lisbon",
      "tzCode": "Europe/Lisbon",
      "name": "Lisbon, Porto, Amadora, Braga, Setúbal",
      "utc": "+00:00"
  },
  {
      "label": "Europe/London",
      "tzCode": "Europe/London",
      "name": "London, Birmingham, Liverpool, Sheffield, Bristol",
      "utc": "+00:00"
  },
  {
      "label": "Africa/Algiers",
      "tzCode": "Africa/Algiers",
      "name": "Algiers, Boumerdas, Oran, Tébessa, Constantine",
      "utc": "+01:00"
  },
  {
      "label": "Africa/Bangui",
      "tzCode": "Africa/Bangui",
      "name": "Bangui, Bimbo, Mbaïki, Berbérati, Kaga Bandoro",
      "utc": "+01:00"
  },
  {
      "label": "Africa/Brazzaville",
      "tzCode": "Africa/Brazzaville",
      "name": "Brazzaville, Pointe-Noire, Dolisie, Kayes, Owando",
      "utc": "+01:00"
  },
  {
      "label": "Africa/Ceuta",
      "tzCode": "Africa/Ceuta",
      "name": "Ceuta, Melilla",
      "utc": "+01:00"
  },
  {
      "label": "Africa/Douala",
      "tzCode": "Africa/Douala",
      "name": "Douala, Yaoundé, Garoua, Kousséri, Bamenda",
      "utc": "+01:00"
  },
  {
      "label": "Africa/Kinshasa",
      "tzCode": "Africa/Kinshasa",
      "name": "Kinshasa, Masina, Kikwit, Mbandaka, Matadi",
      "utc": "+01:00"
  },
  {
      "label": "Africa/Lagos",
      "tzCode": "Africa/Lagos",
      "name": "Lagos, Kano, Ibadan, Kaduna, Port Harcourt",
      "utc": "+01:00"
  },
  {
      "label": "Africa/Libreville",
      "tzCode": "Africa/Libreville",
      "name": "Libreville, Port-Gentil, Franceville, Oyem, Moanda",
      "utc": "+01:00"
  },
  {
      "label": "Africa/Luanda",
      "tzCode": "Africa/Luanda",
      "name": "Luanda, N’dalatando, Huambo, Lobito, Benguela",
      "utc": "+01:00"
  },
  {
      "label": "Africa/Malabo",
      "tzCode": "Africa/Malabo",
      "name": "Bata, Malabo, Ebebiyin, Aconibe, Añisoc",
      "utc": "+01:00"
  },
  {
      "label": "Africa/Ndjamena",
      "tzCode": "Africa/Ndjamena",
      "name": "N'Djamena, Moundou, Sarh, Abéché, Kelo",
      "utc": "+01:00"
  },
  {
      "label": "Africa/Niamey",
      "tzCode": "Africa/Niamey",
      "name": "Niamey, Zinder, Maradi, Agadez, Alaghsas",
      "utc": "+01:00"
  },
  {
      "label": "Africa/Porto-Novo",
      "tzCode": "Africa/Porto-Novo",
      "name": "Cotonou, Abomey-Calavi, Djougou, Porto-Novo, Parakou",
      "utc": "+01:00"
  },
  {
      "label": "Africa/Tunis",
      "tzCode": "Africa/Tunis",
      "name": "Tunis, Sfax, Sousse, Kairouan, Bizerte",
      "utc": "+01:00"
  },
  {
      "label": "Africa/Windhoek",
      "tzCode": "Africa/Windhoek",
      "name": "Windhoek, Rundu, Walvis Bay, Oshakati, Swakopmund",
      "utc": "+01:00"
  },
  {
      "label": "Arctic/Longyearbyen",
      "tzCode": "Arctic/Longyearbyen",
      "name": "Longyearbyen, Olonkinbyen",
      "utc": "+01:00"
  },
  {
      "label": "Europe/Amsterdam",
      "tzCode": "Europe/Amsterdam",
      "name": "Amsterdam, Rotterdam, The Hague, Utrecht, Eindhoven",
      "utc": "+01:00"
  },
  {
      "label": "Europe/Andorra",
      "tzCode": "Europe/Andorra",
      "name": "Andorra la Vella, les Escaldes, Encamp, Sant Julià de Lòria, la Massana",
      "utc": "+01:00"
  },
  {
      "label": "Europe/Belgrade",
      "tzCode": "Europe/Belgrade",
      "name": "Belgrade, Pristina, Niš, Novi Sad, Prizren",
      "utc": "+01:00"
  },
  {
      "label": "Europe/Berlin",
      "tzCode": "Europe/Berlin",
      "name": "Berlin, Hamburg, Munich, Köln, Frankfurt am Main",
      "utc": "+01:00"
  },
  {
      "label": "Europe/Bratislava",
      "tzCode": "Europe/Bratislava",
      "name": "Bratislava, Košice, Prešov, Nitra, Žilina",
      "utc": "+01:00"
  },
  {
      "label": "Europe/Brussels",
      "tzCode": "Europe/Brussels",
      "name": "Brussels, Antwerpen, Gent, Charleroi, Liège",
      "utc": "+01:00"
  },
  {
      "label": "Europe/Budapest",
      "tzCode": "Europe/Budapest",
      "name": "Budapest, Debrecen, Miskolc, Szeged, Pécs",
      "utc": "+01:00"
  },
  {
      "label": "Europe/Copenhagen",
      "tzCode": "Europe/Copenhagen",
      "name": "Copenhagen, Århus, Odense, Aalborg, Frederiksberg",
      "utc": "+01:00"
  },
  {
      "label": "Europe/Gibraltar",
      "tzCode": "Europe/Gibraltar",
      "name": "Gibraltar",
      "utc": "+01:00"
  },
  {
      "label": "Europe/Ljubljana",
      "tzCode": "Europe/Ljubljana",
      "name": "Ljubljana, Maribor, Celje, Kranj, Velenje",
      "utc": "+01:00"
  },
  {
      "label": "Europe/Luxembourg",
      "tzCode": "Europe/Luxembourg",
      "name": "Luxembourg, Esch-sur-Alzette, Dudelange, Schifflange, Bettembourg",
      "utc": "+01:00"
  },
  {
      "label": "Europe/Madrid",
      "tzCode": "Europe/Madrid",
      "name": "Madrid, Barcelona, Valencia, Sevilla, Zaragoza",
      "utc": "+01:00"
  },
  {
      "label": "Europe/Malta",
      "tzCode": "Europe/Malta",
      "name": "Birkirkara, Qormi, Mosta, Żabbar, San Pawl il-Baħar",
      "utc": "+01:00"
  },
  {
      "label": "Europe/Monaco",
      "tzCode": "Europe/Monaco",
      "name": "Monaco, Monte-Carlo, La Condamine",
      "utc": "+01:00"
  },
  {
      "label": "Europe/Oslo",
      "tzCode": "Europe/Oslo",
      "name": "Oslo, Bergen, Trondheim, Stavanger, Drammen",
      "utc": "+01:00"
  },
  {
      "label": "Europe/Paris",
      "tzCode": "Europe/Paris",
      "name": "Paris, Marseille, Lyon, Toulouse, Nice",
      "utc": "+01:00"
  },
  {
      "label": "Europe/Podgorica",
      "tzCode": "Europe/Podgorica",
      "name": "Podgorica, Nikšić, Herceg Novi, Pljevlja, Budva",
      "utc": "+01:00"
  },
  {
      "label": "Europe/Prague",
      "tzCode": "Europe/Prague",
      "name": "Prague, Brno, Ostrava, Pilsen, Olomouc",
      "utc": "+01:00"
  },
  {
      "label": "Europe/Rome",
      "tzCode": "Europe/Rome",
      "name": "Rome, Milan, Naples, Turin, Palermo",
      "utc": "+01:00"
  },
  {
      "label": "Europe/San_Marino",
      "tzCode": "Europe/San_Marino",
      "name": "Serravalle, Borgo Maggiore, San Marino, Domagnano, Fiorentino",
      "utc": "+01:00"
  },
  {
      "label": "Europe/Sarajevo",
      "tzCode": "Europe/Sarajevo",
      "name": "Sarajevo, Banja Luka, Zenica, Tuzla, Mostar",
      "utc": "+01:00"
  },
  {
      "label": "Europe/Skopje",
      "tzCode": "Europe/Skopje",
      "name": "Skopje, Bitola, Kumanovo, Prilep, Tetovo",
      "utc": "+01:00"
  },
  {
      "label": "Europe/Stockholm",
      "tzCode": "Europe/Stockholm",
      "name": "Stockholm, Göteborg, Malmö, Uppsala, Sollentuna",
      "utc": "+01:00"
  },
  {
      "label": "Europe/Tirane",
      "tzCode": "Europe/Tirane",
      "name": "Tirana, Durrës, Elbasan, Vlorë, Shkodër",
      "utc": "+01:00"
  },
  {
      "label": "Europe/Vaduz",
      "tzCode": "Europe/Vaduz",
      "name": "Schaan, Vaduz, Triesen, Balzers, Eschen",
      "utc": "+01:00"
  },
  {
      "label": "Europe/Vatican",
      "tzCode": "Europe/Vatican",
      "name": "Vatican City",
      "utc": "+01:00"
  },
  {
      "label": "Europe/Vienna",
      "tzCode": "Europe/Vienna",
      "name": "Vienna, Graz, Linz, Favoriten, Donaustadt",
      "utc": "+01:00"
  },
  {
      "label": "Europe/Warsaw",
      "tzCode": "Europe/Warsaw",
      "name": "Warsaw, Łódź, Kraków, Wrocław, Poznań",
      "utc": "+01:00"
  },
  {
      "label": "Europe/Zagreb",
      "tzCode": "Europe/Zagreb",
      "name": "Zagreb, Split, Rijeka, Osijek, Zadar",
      "utc": "+01:00"
  },
  {
      "label": "Europe/Zurich",
      "tzCode": "Europe/Zurich",
      "name": "Zürich, Genève, Basel, Lausanne, Bern",
      "utc": "+01:00"
  },
  {
      "label": "Africa/Blantyre",
      "tzCode": "Africa/Blantyre",
      "name": "Lilongwe, Blantyre, Mzuzu, Zomba, Kasungu",
      "utc": "+02:00"
  },
  {
      "label": "Africa/Bujumbura",
      "tzCode": "Africa/Bujumbura",
      "name": "Bujumbura, Muyinga, Gitega, Ruyigi, Ngozi",
      "utc": "+02:00"
  },
  {
      "label": "Africa/Cairo",
      "tzCode": "Africa/Cairo",
      "name": "Cairo, Alexandria, Giza, Port Said, Suez",
      "utc": "+02:00"
  },
  {
      "label": "Africa/Gaborone",
      "tzCode": "Africa/Gaborone",
      "name": "Gaborone, Francistown, Molepolole, Selebi-Phikwe, Maun",
      "utc": "+02:00"
  },
  {
      "label": "Africa/Harare",
      "tzCode": "Africa/Harare",
      "name": "Harare, Bulawayo, Chitungwiza, Mutare, Gweru",
      "utc": "+02:00"
  },
  {
      "label": "Africa/Johannesburg",
      "tzCode": "Africa/Johannesburg",
      "name": "Cape Town, Durban, Johannesburg, Soweto, Pretoria",
      "utc": "+02:00"
  },
  {
      "label": "Africa/Juba",
      "tzCode": "Africa/Juba",
      "name": "Juba, Winejok, Malakal, Wau, Kuacjok",
      "utc": "+02:00"
  },
  {
      "label": "Africa/Khartoum",
      "tzCode": "Africa/Khartoum",
      "name": "Khartoum, Omdurman, Nyala, Port Sudan, Kassala",
      "utc": "+02:00"
  },
  {
      "label": "Africa/Kigali",
      "tzCode": "Africa/Kigali",
      "name": "Kigali, Butare, Gitarama, Musanze, Gisenyi",
      "utc": "+02:00"
  },
  {
      "label": "Africa/Lubumbashi",
      "tzCode": "Africa/Lubumbashi",
      "name": "Lubumbashi, Mbuji-Mayi, Kisangani, Kananga, Likasi",
      "utc": "+02:00"
  },
  {
      "label": "Africa/Lusaka",
      "tzCode": "Africa/Lusaka",
      "name": "Lusaka, Kitwe, Ndola, Kabwe, Chingola",
      "utc": "+02:00"
  },
  {
      "label": "Africa/Maputo",
      "tzCode": "Africa/Maputo",
      "name": "Maputo, Matola, Beira, Nampula, Chimoio",
      "utc": "+02:00"
  },
  {
      "label": "Africa/Maseru",
      "tzCode": "Africa/Maseru",
      "name": "Maseru, Mafeteng, Leribe, Maputsoe, Mohale’s Hoek",
      "utc": "+02:00"
  },
  {
      "label": "Africa/Mbabane",
      "tzCode": "Africa/Mbabane",
      "name": "Manzini, Mbabane, Big Bend, Malkerns, Nhlangano",
      "utc": "+02:00"
  },
  {
      "label": "Africa/Tripoli",
      "tzCode": "Africa/Tripoli",
      "name": "Tripoli, Benghazi, Mişrātah, Tarhuna, Al Khums",
      "utc": "+02:00"
  },
  {
      "label": "Asia/Amman",
      "tzCode": "Asia/Amman",
      "name": "Amman, Zarqa, Irbid, Russeifa, Wādī as Sīr",
      "utc": "+02:00"
  },
  {
      "label": "Asia/Beirut",
      "tzCode": "Asia/Beirut",
      "name": "Beirut, Ra’s Bayrūt, Tripoli, Sidon, Tyre",
      "utc": "+02:00"
  },
  {
      "label": "Asia/Damascus",
      "tzCode": "Asia/Damascus",
      "name": "Aleppo, Damascus, Homs, Ḩamāh, Latakia",
      "utc": "+02:00"
  },
  {
      "label": "Asia/Famagusta",
      "tzCode": "Asia/Famagusta",
      "name": "Famagusta, Kyrenia, Protaras, Paralímni, Lápithos",
      "utc": "+02:00"
  },
  {
      "label": "Asia/Gaza",
      "tzCode": "Asia/Gaza",
      "name": "Gaza, Khān Yūnis, Jabālyā, Rafaḩ, Dayr al Balaḩ",
      "utc": "+02:00"
  },
  {
      "label": "Asia/Hebron",
      "tzCode": "Asia/Hebron",
      "name": "East Jerusalem, Hebron, Nablus, Battir, Ţūlkarm",
      "utc": "+02:00"
  },
  {
      "label": "Asia/Jerusalem",
      "tzCode": "Asia/Jerusalem",
      "name": "Jerusalem, Tel Aviv, West Jerusalem, Haifa, Ashdod",
      "utc": "+02:00"
  },
  {
      "label": "Asia/Nicosia",
      "tzCode": "Asia/Nicosia",
      "name": "Nicosia, Limassol, Larnaca, Stróvolos, Paphos",
      "utc": "+02:00"
  },
  {
      "label": "Europe/Athens",
      "tzCode": "Europe/Athens",
      "name": "Athens, Thessaloníki, Pátra, Piraeus, Lárisa",
      "utc": "+02:00"
  },
  {
      "label": "Europe/Bucharest",
      "tzCode": "Europe/Bucharest",
      "name": "Bucharest, Sector 3, Sector 6, Sector 2, Iaşi",
      "utc": "+02:00"
  },
  {
      "label": "Europe/Chisinau",
      "tzCode": "Europe/Chisinau",
      "name": "Chisinau, Tiraspol, Bălţi, Bender, Rîbniţa",
      "utc": "+02:00"
  },
  {
      "label": "Europe/Helsinki",
      "tzCode": "Europe/Helsinki",
      "name": "Helsinki, Espoo, Tampere, Vantaa, Turku",
      "utc": "+02:00"
  },
  {
      "label": "Europe/Kaliningrad",
      "tzCode": "Europe/Kaliningrad",
      "name": "Kaliningrad, Chernyakhovsk, Sovetsk, Baltiysk, Gusev",
      "utc": "+02:00"
  },
  {
      "label": "Europe/Kyiv",
      "tzCode": "Europe/Kyiv",
      "name": "Kyiv, Kharkiv, Donetsk, Odessa, Dnipro",
      "utc": "+02:00"
  },
  {
      "label": "Europe/Mariehamn",
      "tzCode": "Europe/Mariehamn",
      "name": "Mariehamn",
      "utc": "+02:00"
  },
  {
      "label": "Europe/Riga",
      "tzCode": "Europe/Riga",
      "name": "Riga, Daugavpils, Liepāja, Jelgava, Jūrmala",
      "utc": "+02:00"
  },
  {
      "label": "Europe/Sofia",
      "tzCode": "Europe/Sofia",
      "name": "Sofia, Plovdiv, Varna, Burgas, Ruse",
      "utc": "+02:00"
  },
  {
      "label": "Europe/Tallinn",
      "tzCode": "Europe/Tallinn",
      "name": "Tallinn, Tartu, Narva, Kohtla-Järve, Pärnu",
      "utc": "+02:00"
  },
  {
      "label": "Europe/Uzhgorod",
      "tzCode": "Europe/Uzhgorod",
      "name": "Uzhgorod, Mukachevo, Khust, Berehove, Tyachiv",
      "utc": "+02:00"
  },
  {
      "label": "Europe/Vilnius",
      "tzCode": "Europe/Vilnius",
      "name": "Vilnius, Kaunas, Klaipėda, Šiauliai, Panevėžys",
      "utc": "+02:00"
  },
  {
      "label": "Europe/Zaporozhye",
      "tzCode": "Europe/Zaporozhye",
      "name": "Luhansk, Sevastopol, Sievierodonetsk, Alchevsk, Lysychansk",
      "utc": "+02:00"
  },
  {
      "label": "Africa/Addis_Ababa",
      "tzCode": "Africa/Addis_Ababa",
      "name": "Addis Ababa, Dire Dawa, Mek'ele, Nazrēt, Bahir Dar",
      "utc": "+03:00"
  },
  {
      "label": "Africa/Asmara",
      "tzCode": "Africa/Asmara",
      "name": "Asmara, Keren, Massawa, Assab, Mendefera",
      "utc": "+03:00"
  },
  {
      "label": "Africa/Dar_es_Salaam",
      "tzCode": "Africa/Dar_es_Salaam",
      "name": "Dar es Salaam, Mwanza, Zanzibar, Arusha, Mbeya",
      "utc": "+03:00"
  },
  {
      "label": "Africa/Djibouti",
      "tzCode": "Africa/Djibouti",
      "name": "Djibouti, 'Ali Sabieh, Tadjourah, Obock, Dikhil",
      "utc": "+03:00"
  },
  {
      "label": "Africa/Kampala",
      "tzCode": "Africa/Kampala",
      "name": "Kampala, Gulu, Lira, Mbarara, Jinja",
      "utc": "+03:00"
  },
  {
      "label": "Africa/Mogadishu",
      "tzCode": "Africa/Mogadishu",
      "name": "Mogadishu, Hargeysa, Berbera, Kismayo, Marka",
      "utc": "+03:00"
  },
  {
      "label": "Africa/Nairobi",
      "tzCode": "Africa/Nairobi",
      "name": "Nairobi, Mombasa, Nakuru, Eldoret, Kisumu",
      "utc": "+03:00"
  },
  {
      "label": "Antarctica/Syowa",
      "tzCode": "Antarctica/Syowa",
      "name": "Syowa",
      "utc": "+03:00"
  },
  {
      "label": "Asia/Aden",
      "tzCode": "Asia/Aden",
      "name": "Sanaa, Al Ḩudaydah, Taiz, Aden, Mukalla",
      "utc": "+03:00"
  },
  {
      "label": "Asia/Baghdad",
      "tzCode": "Asia/Baghdad",
      "name": "Baghdad, Basrah, Al Mawşil al Jadīdah, Al Başrah al Qadīmah, Mosul",
      "utc": "+03:00"
  },
  {
      "label": "Asia/Bahrain",
      "tzCode": "Asia/Bahrain",
      "name": "Manama, Al Muharraq, Ar Rifā‘, Dār Kulayb, Madīnat Ḩamad",
      "utc": "+03:00"
  },
  {
      "label": "Asia/Kuwait",
      "tzCode": "Asia/Kuwait",
      "name": "Al Aḩmadī, Ḩawallī, As Sālimīyah, Şabāḩ as Sālim, Al Farwānīyah",
      "utc": "+03:00"
  },
  {
      "label": "Asia/Qatar",
      "tzCode": "Asia/Qatar",
      "name": "Doha, Ar Rayyān, Umm Şalāl Muḩammad, Al Wakrah, Al Khawr",
      "utc": "+03:00"
  },
  {
      "label": "Asia/Riyadh",
      "tzCode": "Asia/Riyadh",
      "name": "Riyadh, Jeddah, Mecca, Medina, Sulţānah",
      "utc": "+03:00"
  },
  {
      "label": "Europe/Istanbul",
      "tzCode": "Europe/Istanbul",
      "name": "Istanbul, Ankara, İzmir, Bursa, Adana",
      "utc": "+03:00"
  },
  {
      "label": "Europe/Kirov",
      "tzCode": "Europe/Kirov",
      "name": "Kirov, Kirovo-Chepetsk, Vyatskiye Polyany, Slobodskoy, Kotel’nich",
      "utc": "+03:00"
  },
  {
      "label": "Europe/Minsk",
      "tzCode": "Europe/Minsk",
      "name": "Minsk, Homyel', Mahilyow, Vitebsk, Hrodna",
      "utc": "+03:00"
  },
  {
      "label": "Europe/Moscow",
      "tzCode": "Europe/Moscow",
      "name": "Moscow, Saint Petersburg, Nizhniy Novgorod, Kazan, Rostov-na-Donu",
      "utc": "+03:00"
  },
  {
      "label": "Europe/Simferopol",
      "tzCode": "Europe/Simferopol",
      "name": "Simferopol, Kerch, Yevpatoriya, Yalta, Feodosiya",
      "utc": "+03:00"
  },
  {
      "label": "Europe/Volgograd",
      "tzCode": "Europe/Volgograd",
      "name": "Volgograd, Volzhskiy, Kamyshin, Mikhaylovka, Uryupinsk",
      "utc": "+03:00"
  },
  {
      "label": "Indian/Antananarivo",
      "tzCode": "Indian/Antananarivo",
      "name": "Antananarivo, Toamasina, Antsirabe, Fianarantsoa, Mahajanga",
      "utc": "+03:00"
  },
  {
      "label": "Indian/Comoro",
      "tzCode": "Indian/Comoro",
      "name": "Moroni, Moutsamoudou, Fomboni, Domoni, Tsimbeo",
      "utc": "+03:00"
  },
  {
      "label": "Indian/Mayotte",
      "tzCode": "Indian/Mayotte",
      "name": "Mamoudzou, Koungou, Dzaoudzi, Dembeni, Sada",
      "utc": "+03:00"
  },
  {
      "label": "Asia/Tehran",
      "tzCode": "Asia/Tehran",
      "name": "Tehran, Mashhad, Isfahan, Karaj, Tabriz",
      "utc": "+03:30"
  },
  {
      "label": "Asia/Baku",
      "tzCode": "Asia/Baku",
      "name": "Baku, Ganja, Sumqayıt, Lankaran, Yevlakh",
      "utc": "+04:00"
  },
  {
      "label": "Asia/Dubai",
      "tzCode": "Asia/Dubai",
      "name": "Dubai, Sharjah, Abu Dhabi, Ajman City, Ras Al Khaimah City",
      "utc": "+04:00"
  },
  {
      "label": "Asia/Muscat",
      "tzCode": "Asia/Muscat",
      "name": "Muscat, Seeb, Şalālah, Bawshar, Sohar",
      "utc": "+04:00"
  },
  {
      "label": "Asia/Tbilisi",
      "tzCode": "Asia/Tbilisi",
      "name": "Tbilisi, Kutaisi, Batumi, Sokhumi, Zugdidi",
      "utc": "+04:00"
  },
  {
      "label": "Asia/Yerevan",
      "tzCode": "Asia/Yerevan",
      "name": "Yerevan, Gyumri, Vanadzor, Vagharshapat, Hrazdan",
      "utc": "+04:00"
  },
  {
      "label": "Europe/Astrakhan",
      "tzCode": "Europe/Astrakhan",
      "name": "Astrakhan, Akhtubinsk, Znamensk, Kharabali, Kamyzyak",
      "utc": "+04:00"
  },
  {
      "label": "Europe/Samara",
      "tzCode": "Europe/Samara",
      "name": "Samara, Togliatti-on-the-Volga, Izhevsk, Syzran’, Novokuybyshevsk",
      "utc": "+04:00"
  },
  {
      "label": "Europe/Saratov",
      "tzCode": "Europe/Saratov",
      "name": "Saratov, Balakovo, Engel’s, Balashov, Vol’sk",
      "utc": "+04:00"
  },
  {
      "label": "Europe/Ulyanovsk",
      "tzCode": "Europe/Ulyanovsk",
      "name": "Ulyanovsk, Dimitrovgrad, Inza, Barysh, Novoul’yanovsk",
      "utc": "+04:00"
  },
  {
      "label": "Indian/Mahe",
      "tzCode": "Indian/Mahe",
      "name": "Victoria, Anse Boileau, Bel Ombre, Beau Vallon, Cascade",
      "utc": "+04:00"
  },
  {
      "label": "Indian/Mauritius",
      "tzCode": "Indian/Mauritius",
      "name": "Port Louis, Beau Bassin-Rose Hill, Vacoas, Curepipe, Quatre Bornes",
      "utc": "+04:00"
  },
  {
      "label": "Indian/Reunion",
      "tzCode": "Indian/Reunion",
      "name": "Saint-Denis, Saint-Paul, Saint-Pierre, Le Tampon, Saint-André",
      "utc": "+04:00"
  },
  {
      "label": "Asia/Kabul",
      "tzCode": "Asia/Kabul",
      "name": "Kabul, Kandahār, Mazār-e Sharīf, Herāt, Jalālābād",
      "utc": "+04:30"
  },
  {
      "label": "Antarctica/Mawson",
      "tzCode": "Antarctica/Mawson",
      "name": "Mawson",
      "utc": "+05:00"
  },
  {
      "label": "Asia/Aqtau",
      "tzCode": "Asia/Aqtau",
      "name": "Shevchenko, Zhanaozen, Beyneu, Shetpe, Yeraliyev",
      "utc": "+05:00"
  },
  {
      "label": "Asia/Aqtobe",
      "tzCode": "Asia/Aqtobe",
      "name": "Aktobe, Kandyagash, Shalqar, Khromtau, Embi",
      "utc": "+05:00"
  },
  {
      "label": "Asia/Ashgabat",
      "tzCode": "Asia/Ashgabat",
      "name": "Ashgabat, Türkmenabat, Daşoguz, Mary, Balkanabat",
      "utc": "+05:00"
  },
  {
      "label": "Asia/Atyrau",
      "tzCode": "Asia/Atyrau",
      "name": "Atyrau, Qulsary, Shalkar, Balykshi, Maqat",
      "utc": "+05:00"
  },
  {
      "label": "Asia/Dushanbe",
      "tzCode": "Asia/Dushanbe",
      "name": "Dushanbe, Khujand, Kŭlob, Bokhtar, Istaravshan",
      "utc": "+05:00"
  },
  {
      "label": "Asia/Karachi",
      "tzCode": "Asia/Karachi",
      "name": "Karachi, Lahore, Faisalabad, Rawalpindi, Multan",
      "utc": "+05:00"
  },
  {
      "label": "Asia/Oral",
      "tzCode": "Asia/Oral",
      "name": "Oral, Aqsay, Zhänibek, Tasqala, Zhumysker",
      "utc": "+05:00"
  },
  {
      "label": "Asia/Qyzylorda",
      "tzCode": "Asia/Qyzylorda",
      "name": "Kyzylorda, Baikonur, Novokazalinsk, Aral, Chiili",
      "utc": "+05:00"
  },
  {
      "label": "Asia/Samarkand",
      "tzCode": "Asia/Samarkand",
      "name": "Samarkand, Bukhara, Nukus, Qarshi, Jizzax",
      "utc": "+05:00"
  },
  {
      "label": "Asia/Tashkent",
      "tzCode": "Asia/Tashkent",
      "name": "Tashkent, Namangan, Andijon, Qo‘qon, Chirchiq",
      "utc": "+05:00"
  },
  {
      "label": "Asia/Yekaterinburg",
      "tzCode": "Asia/Yekaterinburg",
      "name": "Yekaterinburg, Chelyabinsk, Ufa, Perm, Orenburg",
      "utc": "+05:00"
  },
  {
      "label": "Indian/Kerguelen",
      "tzCode": "Indian/Kerguelen",
      "name": "Port-aux-Français",
      "utc": "+05:00"
  },
  {
      "label": "Indian/Maldives",
      "tzCode": "Indian/Maldives",
      "name": "Male, Fuvahmulah, Hithadhoo, Kulhudhuffushi, Thinadhoo",
      "utc": "+05:00"
  },
  {
      "label": "Asia/Colombo",
      "tzCode": "Asia/Colombo",
      "name": "Colombo, Dehiwala-Mount Lavinia, Moratuwa, Jaffna, Negombo",
      "utc": "+05:30"
  },
  {
      "label": "Asia/Kolkata",
      "tzCode": "Asia/Kolkata",
      "name": "Mumbai, Delhi, Bengaluru, Kolkata, Chennai",
      "utc": "+05:30"
  },
  {
      "label": "Asia/Calcutta",
      "tzCode": "Asia/Calcutta",
      "name": "Mumbai, Delhi, Bengaluru, Kolkata, Chennai",
      "utc": "+05:30"
  },
  {
      "label": "Asia/Kathmandu",
      "tzCode": "Asia/Kathmandu",
      "name": "Kathmandu, Pokhara, Pātan, Biratnagar, Birgañj",
      "utc": "+05:45"
  },
  {
      "label": "Antarctica/Vostok",
      "tzCode": "Antarctica/Vostok",
      "name": "Vostok",
      "utc": "+06:00"
  },
  {
      "label": "Asia/Almaty",
      "tzCode": "Asia/Almaty",
      "name": "Almaty, Karagandy, Shymkent, Taraz, Nur-Sultan",
      "utc": "+06:00"
  },
  {
      "label": "Asia/Bishkek",
      "tzCode": "Asia/Bishkek",
      "name": "Bishkek, Osh, Jalal-Abad, Karakol, Tokmok",
      "utc": "+06:00"
  },
  {
      "label": "Asia/Dhaka",
      "tzCode": "Asia/Dhaka",
      "name": "Dhaka, Chattogram, Khulna, Rājshāhi, Comilla",
      "utc": "+06:00"
  },
  {
      "label": "Asia/Omsk",
      "tzCode": "Asia/Omsk",
      "name": "Omsk, Tara, Kalachinsk, Znamenskoye, Tavricheskoye",
      "utc": "+06:00"
  },
  {
      "label": "Asia/Qostanay",
      "tzCode": "Asia/Qostanay",
      "name": "Kostanay, Rudnyy, Dzhetygara, Arkalyk, Lisakovsk",
      "utc": "+06:00"
  },
  {
      "label": "Asia/Thimphu",
      "tzCode": "Asia/Thimphu",
      "name": "himphu, Punākha, Tsirang, Phuntsholing, Pemagatshel",
      "utc": "+06:00"
  },
  {
      "label": "Asia/Urumqi",
      "tzCode": "Asia/Urumqi",
      "name": "Zhongshan, Ürümqi, Zhanjiang, Shihezi, Huocheng",
      "utc": "+06:00"
  },
  {
      "label": "Indian/Chagos",
      "tzCode": "Indian/Chagos",
      "name": "British Indian Ocean Territory",
      "utc": "+06:00"
  },
  {
      "label": "Asia/Yangon",
      "tzCode": "Asia/Yangon",
      "name": "Yangon, Mandalay, Nay Pyi Taw, Mawlamyine, Kyain Seikgyi Township",
      "utc": "+06:30"
  },
  {
      "label": "Indian/Cocos",
      "tzCode": "Indian/Cocos",
      "name": "West Island",
      "utc": "+06:30"
  },
  {
      "label": "Antarctica/Davis",
      "tzCode": "Antarctica/Davis",
      "name": "Davis",
      "utc": "+07:00"
  },
  {
      "label": "Asia/Bangkok",
      "tzCode": "Asia/Bangkok",
      "name": "Bangkok, Hanoi, Haiphong, Samut Prakan, Mueang Nonthaburi",
      "utc": "+07:00"
  },
  {
      "label": "Asia/Barnaul",
      "tzCode": "Asia/Barnaul",
      "name": "Barnaul, Biysk, Rubtsovsk, Novoaltaysk, Gorno-Altaysk",
      "utc": "+07:00"
  },
  {
      "label": "Asia/Hovd",
      "tzCode": "Asia/Hovd",
      "name": "Khovd, Ölgii, Ulaangom, Uliastay, Altai",
      "utc": "+07:00"
  },
  {
      "label": "Asia/Ho_Chi_Minh",
      "tzCode": "Asia/Ho_Chi_Minh",
      "name": "Ho Chi Minh City, Da Nang, Biên Hòa, Nha Trang, Cần Thơ",
      "utc": "+07:00"
  },
  {
      "label": "Asia/Jakarta",
      "tzCode": "Asia/Jakarta",
      "name": "Jakarta, Surabaya, Medan, Bandung, Bekasi",
      "utc": "+07:00"
  },
  {
      "label": "Asia/Krasnoyarsk",
      "tzCode": "Asia/Krasnoyarsk",
      "name": "Krasnoyarsk, Abakan, Norilsk, Achinsk, Kyzyl",
      "utc": "+07:00"
  },
  {
      "label": "Asia/Novokuznetsk",
      "tzCode": "Asia/Novokuznetsk",
      "name": "Novokuznetsk, Kemerovo, Prokop’yevsk, Leninsk-Kuznetsky, Kiselëvsk",
      "utc": "+07:00"
  },
  {
      "label": "Asia/Novosibirsk",
      "tzCode": "Asia/Novosibirsk",
      "name": "Novosibirsk, Berdsk, Iskitim, Akademgorodok, Kuybyshev",
      "utc": "+07:00"
  },
  {
      "label": "Asia/Phnom_Penh",
      "tzCode": "Asia/Phnom_Penh",
      "name": "Phnom Penh, Takeo, Sihanoukville, Battambang, Siem Reap",
      "utc": "+07:00"
  },
  {
      "label": "Asia/Pontianak",
      "tzCode": "Asia/Pontianak",
      "name": "Pontianak, Tanjung Pinang, Palangkaraya, Singkawang, Sampit",
      "utc": "+07:00"
  },
  {
      "label": "Asia/Tomsk",
      "tzCode": "Asia/Tomsk",
      "name": "Tomsk, Seversk, Strezhevoy, Kolpashevo, Asino",
      "utc": "+07:00"
  },
  {
      "label": "Asia/Vientiane",
      "tzCode": "Asia/Vientiane",
      "name": "Vientiane, Pakse, Thakhèk, Savannakhet, Luang Prabang",
      "utc": "+07:00"
  },
  {
      "label": "Indian/Christmas",
      "tzCode": "Indian/Christmas",
      "name": "Flying Fish Cove",
      "utc": "+07:00"
  },
  {
      "label": "Asia/Brunei",
      "tzCode": "Asia/Brunei",
      "name": "Bandar Seri Begawan, Kuala Belait, Seria, Tutong, Bangar",
      "utc": "+08:00"
  },
  {
      "label": "Asia/Choibalsan",
      "tzCode": "Asia/Choibalsan",
      "name": "Baruun-Urt, Choibalsan",
      "utc": "+08:00"
  },
  {
      "label": "Asia/Hong_Kong",
      "tzCode": "Asia/Hong_Kong",
      "name": "Hong Kong, Kowloon, Tsuen Wan, Yuen Long Kau Hui, Tung Chung",
      "utc": "+08:00"
  },
  {
      "label": "Asia/Irkutsk",
      "tzCode": "Asia/Irkutsk",
      "name": "Irkutsk, Ulan-Ude, Bratsk, Angarsk, Ust’-Ilimsk",
      "utc": "+08:00"
  },
  {
      "label": "Asia/Kuala_Lumpur",
      "tzCode": "Asia/Kuala_Lumpur",
      "name": "Kota Bharu, Kuala Lumpur, Klang, Kampung Baru Subang, Johor Bahru",
      "utc": "+08:00"
  },
  {
      "label": "Asia/Kuching",
      "tzCode": "Asia/Kuching",
      "name": "Kuching, Kota Kinabalu, Sandakan, Tawau, Miri",
      "utc": "+08:00"
  },
  {
      "label": "Asia/Macau",
      "tzCode": "Asia/Macau",
      "name": "Macau",
      "utc": "+08:00"
  },
  {
      "label": "Asia/Makassar",
      "tzCode": "Asia/Makassar",
      "name": "Makassar, Denpasar, City of Balikpapan, Banjarmasin, Manado",
      "utc": "+08:00"
  },
  {
      "label": "Asia/Manila",
      "tzCode": "Asia/Manila",
      "name": "Quezon City, Manila, Caloocan City, Budta, Davao",
      "utc": "+08:00"
  },
  {
      "label": "Asia/Shanghai",
      "tzCode": "Asia/Shanghai",
      "name": "Shanghai, Beijing, Tianjin, Guangzhou, Shenzhen",
      "utc": "+08:00"
  },
  {
      "label": "Asia/Singapore",
      "tzCode": "Asia/Singapore",
      "name": "Singapore, Woodlands",
      "utc": "+08:00"
  },
  {
      "label": "Asia/Taipei",
      "tzCode": "Asia/Taipei",
      "name": "Taipei, Kaohsiung, Taichung, Tainan, Banqiao",
      "utc": "+08:00"
  },
  {
      "label": "Asia/Ulaanbaatar",
      "tzCode": "Asia/Ulaanbaatar",
      "name": "Ulan Bator, Erdenet, Darhan, Hovd, Mörön",
      "utc": "+08:00"
  },
  {
      "label": "Australia/Perth",
      "tzCode": "Australia/Perth",
      "name": "Perth, Rockingham, Mandurah, Bunbury, Albany",
      "utc": "+08:00"
  },
  {
      "label": "Australia/Eucla",
      "tzCode": "Australia/Eucla",
      "name": "Eucla",
      "utc": "+08:45"
  },
  {
      "label": "Asia/Chita",
      "tzCode": "Asia/Chita",
      "name": "Chita, Krasnokamensk, Borzya, Petrovsk-Zabaykal’skiy, Aginskoye",
      "utc": "+09:00"
  },
  {
      "label": "Asia/Dili",
      "tzCode": "Asia/Dili",
      "name": "Dili, Maliana, Suai, Likisá, Aileu",
      "utc": "+09:00"
  },
  {
      "label": "Asia/Jayapura",
      "tzCode": "Asia/Jayapura",
      "name": "Ambon, Jayapura, Sorong, Ternate, Abepura",
      "utc": "+09:00"
  },
  {
      "label": "Asia/Khandyga",
      "tzCode": "Asia/Khandyga",
      "name": "Khandyga",
      "utc": "+09:00"
  },
  {
      "label": "Asia/Pyongyang",
      "tzCode": "Asia/Pyongyang",
      "name": "Pyongyang, Hamhŭng, Namp’o, Sunch’ŏn, Hŭngnam",
      "utc": "+09:00"
  },
  {
      "label": "Asia/Seoul",
      "tzCode": "Asia/Seoul",
      "name": "Seoul, Busan, Incheon, Daegu, Daejeon",
      "utc": "+09:00"
  },
  {
      "label": "Asia/Tokyo",
      "tzCode": "Asia/Tokyo",
      "name": "Tokyo, Yokohama, Osaka, Nagoya, Sapporo",
      "utc": "+09:00"
  },
  {
      "label": "Asia/Yakutsk",
      "tzCode": "Asia/Yakutsk",
      "name": "Yakutsk, Blagoveshchensk, Belogorsk, Neryungri, Svobodnyy",
      "utc": "+09:00"
  },
  {
      "label": "Pacific/Palau",
      "tzCode": "Pacific/Palau",
      "name": "Koror, Koror Town, Kloulklubed, Ulimang, Mengellang",
      "utc": "+09:00"
  },
  {
      "label": "Australia/Adelaide",
      "tzCode": "Australia/Adelaide",
      "name": "Adelaide, Adelaide Hills, Mount Gambier, Morphett Vale, Gawler",
      "utc": "+09:30"
  },
  {
      "label": "Australia/Broken_Hill",
      "tzCode": "Australia/Broken_Hill",
      "name": "Broken Hill",
      "utc": "+09:30"
  },
  {
      "label": "Australia/Darwin",
      "tzCode": "Australia/Darwin",
      "name": "Darwin, Alice Springs, Palmerston, Howard Springs",
      "utc": "+09:30"
  },
  {
      "label": "Antarctica/DumontDUrville",
      "tzCode": "Antarctica/DumontDUrville",
      "name": "DumontDUrville",
      "utc": "+10:00"
  },
  {
      "label": "Antarctica/Macquarie",
      "tzCode": "Antarctica/Macquarie",
      "name": "Macquarie",
      "utc": "+10:00"
  },
  {
      "label": "Asia/Ust-Nera",
      "tzCode": "Asia/Ust-Nera",
      "name": "Ust-Nera",
      "utc": "+10:00"
  },
  {
      "label": "Asia/Vladivostok",
      "tzCode": "Asia/Vladivostok",
      "name": "Vladivostok, Khabarovsk, Khabarovsk Vtoroy, Komsomolsk-on-Amur, Ussuriysk",
      "utc": "+10:00"
  },
  {
      "label": "Australia/Brisbane",
      "tzCode": "Australia/Brisbane",
      "name": "Brisbane, Gold Coast, Logan City, Townsville, Cairns",
      "utc": "+10:00"
  },
  {
      "label": "Australia/Currie",
      "tzCode": "Australia/Currie",
      "name": "Currie",
      "utc": "+10:00"
  },
  {
      "label": "Australia/Hobart",
      "tzCode": "Australia/Hobart",
      "name": "Hobart, Launceston, Burnie, Devonport, Sandy Bay",
      "utc": "+10:00"
  },
  {
      "label": "Australia/Lindeman",
      "tzCode": "Australia/Lindeman",
      "name": "Lindeman",
      "utc": "+10:00"
  },
  {
      "label": "Australia/Melbourne",
      "tzCode": "Australia/Melbourne",
      "name": "Melbourne, Geelong, Bendigo, Ballarat, Melbourne City Centre",
      "utc": "+10:00"
  },
  {
      "label": "Australia/Sydney",
      "tzCode": "Australia/Sydney",
      "name": "Sydney, Canberra, Newcastle, Wollongong, Maitland",
      "utc": "+10:00"
  },
  {
      "label": "Pacific/Chuuk",
      "tzCode": "Pacific/Chuuk",
      "name": "Weno, Colonia",
      "utc": "+10:00"
  },
  {
      "label": "Pacific/GuamVillage",
      "tzCode": "Pacific/GuamVillage",
      "name": "Dededo Village, Yigo Village, Tamuning, Tamuning-Tumon-Harmon Village, Mangilao Village",
      "utc": "+10:00"
  },
  {
      "label": "Pacific/Port_Moresby",
      "tzCode": "Pacific/Port_Moresby",
      "name": "Port Moresby, Lae, Mount Hagen, Popondetta, Madang",
      "utc": "+10:00"
  },
  {
      "label": "Pacific/Saipan",
      "tzCode": "Pacific/Saipan",
      "name": "Saipan, San Jose Village",
      "utc": "+10:00"
  },
  {
      "label": "Australia/Lord_Howe",
      "tzCode": "Australia/Lord_Howe",
      "name": "Lord Howe",
      "utc": "+10:30"
  },
  {
      "label": "Antarctica/Casey",
      "tzCode": "Antarctica/Casey",
      "name": "Casey",
      "utc": "+11:00"
  },
  {
      "label": "Asia/Magadan",
      "tzCode": "Asia/Magadan",
      "name": "Magadan, Ust-Nera, Susuman, Ola",
      "utc": "+11:00"
  },
  {
      "label": "Asia/Sakhalin",
      "tzCode": "Asia/Sakhalin",
      "name": "Yuzhno-Sakhalinsk, Korsakov, Kholmsk, Okha, Nevel’sk",
      "utc": "+11:00"
  },
  {
      "label": "Asia/Srednekolymsk",
      "tzCode": "Asia/Srednekolymsk",
      "name": "Srednekolymsk",
      "utc": "+11:00"
  },
  {
      "label": "Pacific/Bougainville",
      "tzCode": "Pacific/Bougainville",
      "name": "Arawa, Buka",
      "utc": "+11:00"
  },
  {
      "label": "Pacific/Efate",
      "tzCode": "Pacific/Efate",
      "name": "Port-Vila, Luganville, Isangel, Sola, Lakatoro",
      "utc": "+11:00"
  },
  {
      "label": "Pacific/Guadalcanal",
      "tzCode": "Pacific/Guadalcanal",
      "name": "Honiara, Malango, Auki, Gizo, Buala",
      "utc": "+11:00"
  },
  {
      "label": "Pacific/Kosrae",
      "tzCode": "Pacific/Kosrae",
      "name": "Tofol",
      "utc": "+11:00"
  },
  {
      "label": "Pacific/Norfolk",
      "tzCode": "Pacific/Norfolk",
      "name": "Kingston",
      "utc": "+11:00"
  },
  {
      "label": "Pacific/Noumea",
      "tzCode": "Pacific/Noumea",
      "name": "Nouméa, Mont-Dore, Dumbéa, Païta, Wé",
      "utc": "+11:00"
  },
  {
      "label": "Pacific/Pohnpei",
      "tzCode": "Pacific/Pohnpei",
      "name": "Kolonia, Kolonia Town, Palikir - National Government Center",
      "utc": "+11:00"
  },
  {
      "label": "Antarctica/McMurdo",
      "tzCode": "Antarctica/McMurdo",
      "name": "McMurdo",
      "utc": "+12:00"
  },
  {
      "label": "Asia/Anadyr",
      "tzCode": "Asia/Anadyr",
      "name": "Anadyr, Bilibino",
      "utc": "+12:00"
  },
  {
      "label": "Asia/Kamchatka",
      "tzCode": "Asia/Kamchatka",
      "name": "Petropavlovsk-Kamchatsky, Yelizovo, Vilyuchinsk, Klyuchi, Mil’kovo",
      "utc": "+12:00"
  },
  {
      "label": "Pacific/Auckland",
      "tzCode": "Pacific/Auckland",
      "name": "Auckland, Wellington, Christchurch, Manukau City, North Shore",
      "utc": "+12:00"
  },
  {
      "label": "Pacific/Fiji",
      "tzCode": "Pacific/Fiji",
      "name": "Suva, Lautoka, Nadi, Labasa, Ba",
      "utc": "+12:00"
  },
  {
      "label": "Pacific/Funafuti",
      "tzCode": "Pacific/Funafuti",
      "name": "Funafuti, Savave Village, Tanrake Village, Toga Village, Asau Village",
      "utc": "+12:00"
  },
  {
      "label": "Pacific/Kwajalein",
      "tzCode": "Pacific/Kwajalein",
      "name": "Ebaye, Jabat",
      "utc": "+12:00"
  },
  {
      "label": "Pacific/Majuro",
      "tzCode": "Pacific/Majuro",
      "name": "Majuro, Arno, Jabor, Wotje, Mili",
      "utc": "+12:00"
  },
  {
      "label": "Pacific/Nauru",
      "tzCode": "Pacific/Nauru",
      "name": "Yaren, Baiti, Anabar, Uaboe, Ijuw",
      "utc": "+12:00"
  },
  {
      "label": "Pacific/Tarawa",
      "tzCode": "Pacific/Tarawa",
      "name": "Tarawa, Betio Village, Bikenibeu Village",
      "utc": "+12:00"
  },
  {
      "label": "Pacific/Wake",
      "tzCode": "Pacific/Wake",
      "name": "Wake",
      "utc": "+12:00"
  },
  {
      "label": "Pacific/Wallis",
      "tzCode": "Pacific/Wallis",
      "name": "Mata-Utu, Leava, Alo",
      "utc": "+12:00"
  },
  {
      "label": "Pacific/Chatham",
      "tzCode": "Pacific/Chatham",
      "name": "Waitangi",
      "utc": "+12:45"
  },
  {
      "label": "Pacific/Apia",
      "tzCode": "Pacific/Apia",
      "name": "Apia, Asau, Mulifanua, Afega, Leulumoega",
      "utc": "+13:00"
  },
  {
      "label": "Pacific/Enderbury",
      "tzCode": "Pacific/Enderbury",
      "name": "Enderbury",
      "utc": "+13:00"
  },
  {
      "label": "Pacific/Fakaofo",
      "tzCode": "Pacific/Fakaofo",
      "name": "Atafu Village, Nukunonu, Fale old settlement",
      "utc": "+13:00"
  },
  {
      "label": "Pacific/Tongatapu",
      "tzCode": "Pacific/Tongatapu",
      "name": "Nuku‘alofa, Lapaha, Neiafu, Pangai, ‘Ohonua",
      "utc": "+13:00"
  },
  {
      "label": "Pacific/Kiritimati",
      "tzCode": "Pacific/Kiritimati",
      "name": "Kiritimati",
      "utc": "+14:00"
  }
]