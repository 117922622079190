import request from "./apiUM";

const notificationGroupApi = {
  fetchNotificationGroups: (facilityId) => request.get(`/aum/ng/${facilityId}`),
  createNotificationGroup: (data) => request.post("/aum/ng/", data),
  bulkUploadNG: (data) => request.post("/aum/ng/bulk", data),
  deleteNotificationGroup: (groupId) => request.delete(`/aum/ng/${groupId}`),
  removeUserFromNG: (groupId, employeeId) =>
    request.delete(`/aum/ng/member/${groupId}?employeeIds=${employeeId}`),
  addUserToNG: (groupId, employeeId) =>
    request.put(`/aum/ng/member/${groupId}/?employeeIds=${employeeId}`),
  fetchMembersOfNG: (groupId) => request.get(`/aum/ng/members/${groupId}`),
  fetchNGs: () => request.get(`/aum/ng/brief`),
};

export default notificationGroupApi;